import { MUSIC_FILE_MIN_NOTE_OFFSET, MUSIC_FILE_OCTAVES, } from 'music-file';
export const TONES = [
    'C',
    'Db',
    'D',
    'Eb',
    'E',
    'F',
    'Gb',
    'G',
    'Ab',
    'A',
    'Bb',
    'B',
];
export const PITCHES = MUSIC_FILE_OCTAVES.reduce((pitches, octave) => [
    ...pitches,
    ...TONES.map(pitch => `${pitch}${octave}`),
], []);
export const PITCH_INDEX_MAP = PITCHES.reduce((prev, curr, index) => {
    prev[curr] = index;
    return prev;
}, {});
export const getPitchesBetween = (from, to) => {
    return PITCHES.slice(PITCH_INDEX_MAP[from], PITCH_INDEX_MAP[to]);
};
export const getNotePitch = (note, key) => {
    return PITCHES[note.offset + key.offset - MUSIC_FILE_MIN_NOTE_OFFSET];
};
export const getChordPitches = (chord, key) => {
    return chord.notes.map(note => getNotePitch(note, key));
};
