import classNames from 'classnames'
import styles from './index.module.scss'

export interface ToolbarRadioGroupProps<T>
  extends React.HTMLAttributes<HTMLElement> {
  items: ToolbarRadioGroupItem<T>[]
  currentValue: T
  onValueChange?: (value: T) => void
}

export interface ToolbarRadioGroupItem<T> {
  value: T
  icon?: JSX.Element
  title?: string
}

const ToolbarRadioGroup = <T,>({
  className,
  items,
  currentValue,
  onValueChange,
  ...props
}: ToolbarRadioGroupProps<T>) => {
  return (
    <div className={classNames(styles.toolbarRadioGroup, className)} {...props}>
      {items.map(({ icon, title, value }, index) => (
        <div
          key={index}
          className={classNames(styles.item, {
            [styles.selected]: value === currentValue,
          })}
          onClick={() => onValueChange?.(value)}
        >
          {icon && <div className={styles.icon}>{icon}</div>}
          {title && <div className={styles.title}>{title}</div>}
        </div>
      ))}
    </div>
  )
}

export default ToolbarRadioGroup
