export class MFSample {
    constructor(audioBuffer) {
        this.audioBuffer = audioBuffer;
        const { duration } = audioBuffer;
        this.adsr = {
            attackAmp: 1,
            attackTime: 0,
            decayAmp: 0.7,
            decayTime: duration * 0.4,
            sustainAmp: 0.8,
            sustainTime: duration * 0.7,
            releaseAmp: 0,
            releaseTime: duration * 0.2,
        };
    }
}
