import { UNTITLED_MUSIC_FILE } from 'constants/music-file'
import { MFMusicFile } from 'music-file'
import { ChangeActions, makeChanges } from 'music-file-utils'
import {
  createContext,
  PropsWithChildren,
  useContext,
  useRef,
  useState,
} from 'react'

export type MusicFileChangeListener = (current: MFMusicFile) => void

export interface MusicFileContext {
  musicFile: MFMusicFile
  musicFileHistory: {
    hasPrev: boolean
    hasNext: boolean
    undo: () => void
    redo: () => void
  }
  setMusicFile: (replacer: MFMusicFile) => void
  updateMusicFile: (cb: (actions: ChangeActions) => void) => MFMusicFile
}

const context = createContext({} as MusicFileContext)

const { Provider } = context

export const useMusicFileContext = () => useContext(context)

export const MusicFileProvider = ({ children }: PropsWithChildren) => {
  const [musicFile, _setMusicFile] = useState(UNTITLED_MUSIC_FILE)

  const updatesRef = useRef<MFMusicFile[]>([musicFile])

  const prev = updatesRef.current[updatesRef.current.indexOf(musicFile) - 1]
  const next = updatesRef.current[updatesRef.current.indexOf(musicFile) + 1]

  const hasPrev = Boolean(prev)
  const hasNext = Boolean(next)

  const undo = () => {
    if (!hasPrev) {
      throw new Error('cannot undo')
    }

    _setMusicFile(prev)
  }

  const redo = () => {
    if (!hasNext) {
      throw new Error('cannot redo')
    }

    _setMusicFile(next)
  }

  const setMusicFile = (replacer: MFMusicFile) => {
    _setMusicFile(replacer)

    updatesRef.current = [replacer]
  }

  const updateMusicFile = (cb: (actions: ChangeActions) => void) => {
    const updated = makeChanges(musicFile, cb)
    const index = updatesRef.current.indexOf(musicFile)

    updatesRef.current = updatesRef.current.slice(0, index + 1).concat(updated)

    if (updatesRef.current.length > 128) {
      updatesRef.current = updatesRef.current.slice(-128)
    }

    _setMusicFile(updated)

    return updated
  }

  const value: MusicFileContext = {
    musicFile,
    musicFileHistory: {
      hasPrev: Boolean(prev),
      hasNext: Boolean(next),
      undo,
      redo,
    },
    setMusicFile,
    updateMusicFile,
  }

  return <Provider value={value}>{children}</Provider>
}
