import classNames from 'classnames'
import { showModal } from 'components/modal'
import styles from './index.module.scss'

export interface DialogProps<T>
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'content'> {
  title?: string
  description?: string
  content?: React.ReactNode
  buttons?: DialogButtonProps<T>[]
  minWidth?: number
  maxWidth?: number
  onRequestClose?: (value: T) => void
}

export interface DialogButtonProps<T> {
  value: T
  title: string
  variant?: 'primary' | 'secondary' | 'danger'
  onClick?: () => void
}

const Dialog = <T,>({
  className,
  style,
  title,
  description,
  content,
  buttons,
  minWidth = 300,
  maxWidth = 500,
  onRequestClose,
  ...props
}: DialogProps<T>) => {
  return (
    <div
      className={classNames(styles.dialog, className)}
      style={{ minWidth, maxWidth, ...style }}
      {...props}
    >
      {title && <div className={styles.title}>{title}</div>}
      {description && <div className={styles.description}>{description}</div>}
      {content}
      {buttons && (
        <div className={styles.buttons}>
          {buttons.map(
            ({ value, title, variant = 'primary', onClick }, index) => (
              <div
                key={index}
                className={classNames(styles.button, {
                  [styles.primary]: variant === 'primary',
                  [styles.secondary]: variant === 'secondary',
                  [styles.danger]: variant === 'danger',
                })}
                onClick={() => {
                  onClick?.()
                  onRequestClose?.(value)
                }}
              >
                {title}
              </div>
            ),
          )}
        </div>
      )}
    </div>
  )
}

export interface ShowDialogProps<T>
  extends Omit<DialogProps<T>, 'onRequestClose'> {}

export const showDialog = <T,>(props: ShowDialogProps<T> = {}) => {
  return new Promise<Nullable<T>>(resolve => {
    showModal<T>({
      children: ({ dismiss }) => <Dialog onRequestClose={dismiss} {...props} />,
      onClose: resolve,
      transition: 'fadeInOutScale',
    })
  })
}

export default Dialog
