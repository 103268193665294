import classNames from 'classnames'
import { createPortalNode } from 'components/portal'
import useFirstFrameEffect from 'hooks/use-first-frame-effect'
import {
  cloneElement,
  createRef,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import styles from './index.module.scss'

export type ModalTransition =
  | 'fadeInOut'
  | 'fadeInOutLeft'
  | 'fadeInOutRight'
  | 'fadeInOutTop'
  | 'fadeInOutBottom'
  | 'fadeInOutScale'

export interface ModalProps {
  children: JSX.Element
  visible: boolean
  transition?: ModalTransition
  transitionDuration?: number
  className?: string
  style?: React.CSSProperties
  barrierClassName?: string
  barrierStyle?: React.CSSProperties
  onOpen?: () => void
  onClose?: () => void
  onRequestClose?: () => void
}

const buildTransitionStyles = (
  visible: boolean,
  transition: ModalTransition,
  transitionDuration: number,
): React.CSSProperties => {
  switch (transition) {
    case 'fadeInOut':
      return {
        transition: `opacity ${transitionDuration}ms`,
        opacity: visible ? 1 : 0,
      }
    case 'fadeInOutLeft':
      return {
        transition: `opacity ${transitionDuration}ms, transform ${transitionDuration}ms`,
        opacity: visible ? 1 : 0,
        transform: visible ? 'translateX(0)' : 'translateX(-20px)',
      }
    case 'fadeInOutRight':
      return {
        transition: `opacity ${transitionDuration}ms, transform ${transitionDuration}ms`,
        opacity: visible ? 1 : 0,
        transform: visible ? 'translateX(0)' : 'translateX(20px)',
      }
    case 'fadeInOutTop':
      return {
        transition: `opacity ${transitionDuration}ms, transform ${transitionDuration}ms`,
        opacity: visible ? 1 : 0,
        transform: visible ? 'translateY(0)' : 'translateY(-20px)',
      }
    case 'fadeInOutBottom':
      return {
        transition: `opacity ${transitionDuration}ms, transform ${transitionDuration}ms`,
        opacity: visible ? 1 : 0,
        transform: visible ? 'translateY(0)' : 'translateY(20px)',
      }
    case 'fadeInOutScale':
      return {
        transition: `opacity ${transitionDuration}ms, transform ${transitionDuration}ms`,
        opacity: visible ? 1 : 0,
        transform: visible ? 'scale(1.0)' : 'scale(0.95)',
      }
    default:
      return {}
  }
}

const Modal = ({
  children,
  visible,
  transition = 'fadeInOut',
  transitionDuration = 300,
  className,
  style,
  barrierClassName,
  barrierStyle,
  onOpen,
  onClose,
  onRequestClose,
}: ModalProps) => {
  const handleTransitionEnd = () => {
    if (visible) {
      onOpen?.()
    } else {
      onClose?.()
    }
  }

  return (
    <div className={styles.modal}>
      <div
        className={classNames(styles.barrier, barrierClassName)}
        style={{
          transition: `opacity ${transitionDuration}ms`,
          opacity: visible ? 1 : 0,
          ...barrierStyle,
        }}
        onTransitionEnd={handleTransitionEnd}
        onClick={() => onRequestClose?.()}
      />
      {cloneElement(children, {
        ...children.props,
        className: classNames(children.props.className, className),
        style: {
          ...children.props.style,
          pointerEvents: 'auto',
          zIndex: 100,
          ...buildTransitionStyles(visible, transition, transitionDuration),
          ...style,
        },
      })}
    </div>
  )
}

export interface ControlledModalRef {
  show: () => Promise<void>
  hide: () => Promise<void>
}

interface ControlledModalProps
  extends Omit<ModalProps, 'visible' | 'onOpen' | 'onClose'> {}

const ControlledModal = forwardRef<ControlledModalRef, ControlledModalProps>(
  ({ ...props }, ref) => {
    const openEventResolverRef = useRef<() => void>()
    const closeEventResolverRef = useRef<() => void>()

    const [visible, setVisible] = useState(false)

    useFirstFrameEffect(() => {
      setVisible(true)
    })

    useImperativeHandle(ref, () => {
      return {
        show: () =>
          new Promise(resolve => {
            openEventResolverRef.current = resolve
            setVisible(true)
          }),
        hide: () =>
          new Promise(resolve => {
            closeEventResolverRef.current = resolve
            setVisible(false)
          }),
      }
    })

    return (
      <Modal
        visible={visible}
        onOpen={() => openEventResolverRef.current?.()}
        onClose={() => closeEventResolverRef.current?.()}
        {...props}
      />
    )
  },
)

export interface ShowModalProps<T>
  extends Omit<ControlledModalProps, 'children' | 'onRequestClose'> {
  children: (ctx: { dismiss: (value?: Nullable<T>) => void }) => JSX.Element
  onRequestClose?: (ctx: { dismiss: (value?: Nullable<T>) => void }) => void
  onClose?: (value: Nullable<T>) => void
}

export const showModal = <T extends unknown = void>({
  children,
  onRequestClose,
  onClose,
  ...props
}: ShowModalProps<T>) => {
  const ref = createRef<ControlledModalRef>()

  const dismiss = (value: Nullable<T> = null) => {
    ref.current?.hide().then(() => {
      dispose()
      onClose?.(value)
    })
  }

  const dispose = createPortalNode(
    <ControlledModal
      ref={ref}
      onRequestClose={() => onRequestClose?.({ dismiss })}
      {...props}
    >
      {children({ dismiss })}
    </ControlledModal>,
  )

  return dismiss
}

export default Modal
