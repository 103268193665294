import { MFChord } from './chord.js';
import { MFNote } from './note.js';
import { MFRef } from './ref.js';
export class MFTrackItem {
    constructor({ source, begin, duration, }) {
        this.source = source;
        this.begin = begin;
        this.duration = duration;
    }
    static is(other) {
        return other instanceof MFTrackItem;
    }
    static fromJSON(json) {
        if (json.__type !== 'trackItem') {
            throw new Error('invalid trackItem json type');
        }
        const source = json.source.__type === 'note'
            ? MFNote.fromJSON(json.source)
            : json.source.__type === 'chord'
                ? MFChord.fromJSON(json.source)
                : json.source.__type === 'ref'
                    ? MFRef.fromJSON(json.source)
                    : null;
        if (source === null) {
            throw new Error('invalid trackItem source json type');
        }
        return new MFTrackItem({
            source,
            begin: json.begin,
            duration: json.duration,
        });
    }
    get end() {
        return this.begin + this.duration;
    }
    get sourceType() {
        return this.source.constructor;
    }
    isTimeWithin(begin, end) {
        return ((this.begin <= begin && this.end > begin) ||
            (this.begin >= begin && this.begin < end));
    }
    isTimeOverlappedWith(other) {
        return this.isTimeWithin(other.begin, other.end);
    }
    isConsecutiveTo(other) {
        return this.end === other.begin;
    }
    compareTo(other) {
        if (this.begin === other.begin) {
            if (this.end === other.end) {
                return 0;
            }
            return this.end < other.end ? -1 : 1;
        }
        else {
            return this.begin < other.begin ? -1 : 1;
        }
    }
    equals(other) {
        return (other instanceof MFTrackItem &&
            other.source.equals(this.source) &&
            other.begin === this.begin &&
            other.duration === this.duration);
    }
    copy({ source, begin, duration, }) {
        return new MFTrackItem({
            source: source !== null && source !== void 0 ? source : this.source,
            begin: begin !== null && begin !== void 0 ? begin : this.begin,
            duration: duration !== null && duration !== void 0 ? duration : this.duration,
        });
    }
    toJSON() {
        return {
            __type: 'trackItem',
            source: this.source.toJSON(),
            begin: this.begin,
            duration: this.duration,
        };
    }
}
export class MFTrackItemArray {
    constructor(items = []) {
        this.items = items;
    }
    static is(other) {
        return other instanceof MFTrackItemArray;
    }
    static fromJSON(json) {
        return new MFTrackItemArray(json.map(MFTrackItem.fromJSON));
    }
    static fromArray(items) {
        return new MFTrackItemArray(items);
    }
    get length() {
        return this.items.length;
    }
    get isEmpty() {
        return this.length === 0;
    }
    get isNotEmpty() {
        return this.length > 0;
    }
    get first() {
        if (this.isEmpty) {
            throw new Error('no elements in the array');
        }
        return this.items[0];
    }
    get firstOrNull() {
        if (this.isEmpty) {
            throw null;
        }
        return this.items[0];
    }
    get last() {
        if (this.isEmpty) {
            throw new Error('no elements in the array');
        }
        return this.items[this.items.length - 1];
    }
    get lastOrNull() {
        if (this.isEmpty) {
            throw null;
        }
        return this.items[this.items.length - 1];
    }
    has(target) {
        if (typeof target === 'function') {
            return this.items.some(target);
        }
        return this.items.includes(target);
    }
    indexOf(target) {
        if (typeof target === 'function') {
            return this.items.findIndex(target);
        }
        return this.items.indexOf(target);
    }
    at(index) {
        return this.items[index];
    }
    slice(start, end) {
        return new MFTrackItemArray(this.items.slice(start, end));
    }
    computeInsertPos(item) {
        if (this.items.length === 0 || this.items[0].begin > item.begin) {
            return 0;
        }
        else {
            let index = this.items.length - 1;
            while (index >= 0) {
                const current = this.items[index];
                if (current.begin <= item.begin) {
                    if (current.compareTo(item) < 0) {
                        return index + 1;
                    }
                    else {
                        return index;
                    }
                }
                index--;
            }
            return 0;
        }
    }
    insert(item) {
        const index = this.computeInsertPos(item);
        return new MFTrackItemArray(this.items.slice(0, index).concat(item).concat(this.items.slice(index)));
    }
    delete(item) {
        return new MFTrackItemArray(this.items.filter(value => value !== item));
    }
    deleteAt(index) {
        return new MFTrackItemArray(this.items.slice(0, index).concat(this.items.slice(index + 1)));
    }
    replace(source, replacer) {
        return this.delete(source).insert(replacer);
    }
    equals(other) {
        return (other instanceof MFTrackItemArray &&
            other.items.length === this.items.length &&
            other.items.every((val, index) => val === this.items[index]));
    }
    toArray() {
        return this.items;
    }
    toJSON() {
        return this.items.map(item => item.toJSON());
    }
}
