import classNames from 'classnames'
import { useId } from 'react'
import styles from './index.module.scss'

export interface GridlinesProps {
  className?: string
  style?: React.CSSProperties
  canvasWidth: number
  canvasHeight: number
  pattern: GridlinesPatternProps
}

export interface GridlinesPatternProps {
  width: number | string
  height: number | string
  x?: number | string
  y?: number | string
  lines: GridlinesPatternLineProps[]
}

export interface GridlinesPatternLineProps {
  x1: number | string
  x2: number | string
  y1: number | string
  y2: number | string
  stroke: string
  strokeWidth: number
}

const Gridlines = ({
  className,
  style,
  canvasWidth,
  canvasHeight,
  pattern,
}: GridlinesProps) => {
  const patternId = `gridlines_pattern_${useId()}`

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(styles.gridlines, className)}
      style={style}
      width={canvasWidth}
      height={canvasHeight}
    >
      <defs>
        <pattern
          id={patternId}
          x={pattern.x ?? 0}
          y={pattern.y ?? 0}
          width={pattern.width}
          height={pattern.height}
          patternUnits="userSpaceOnUse"
        >
          {pattern.lines.map(({ x1, x2, y1, y2, stroke, strokeWidth }) => (
            <line
              key={`${x1}_${x2}_${y1}_${y2}_${stroke}_${strokeWidth}`}
              x1={x1}
              x2={x2}
              y1={y1}
              y2={y2}
              stroke={stroke}
              strokeWidth={strokeWidth}
            />
          ))}
        </pattern>
      </defs>
      <rect fill={`url(#${patternId})`} width="100%" height="100%" />
    </svg>
  )
}

export default Gridlines
