import { MFMusicFile, MFTrack, MFTrackItem } from 'music-file'

export type ExtractedHTMLElement =
  | {
      type: 'track'
      trackNum: number
      track: MFTrack
    }
  | {
      type: 'trackItem'
      trackNum: number
      track: MFTrack
      trackItem: MFTrackItem
      pos: number
    }
  | { type: 'unknown' }

export const extractHTMLElement = (
  musicFile: MFMusicFile,
  element: HTMLElement,
): ExtractedHTMLElement => {
  const { tracks } = musicFile

  switch (element.dataset.type) {
    case 'track': {
      const trackNum = Number(element.dataset.trackNum)
      const track = tracks.at(trackNum)

      return { type: 'track', trackNum, track }
    }
    case 'trackItem': {
      const trackItemElement = element
      const trackElement = trackItemElement.parentElement!

      const trackNum = Number(trackElement.dataset.trackNum)
      const pos = Number(trackItemElement.dataset.pos)

      const track = tracks.at(trackNum)
      const trackItem = track.items.at(pos)

      return { type: 'trackItem', trackNum, track, trackItem, pos }
    }
    default:
      return { type: 'unknown' }
  }
}
