export interface SqaureProps {
  size: number
  backgroundColor: string
  borderRadius?: number
}

const Sqaure = ({ size, backgroundColor, borderRadius = 5 }: SqaureProps) => {
  return (
    <div
      style={{
        width: size,
        height: size,
        backgroundColor,
        borderRadius,
      }}
    />
  )
}

export default Sqaure
