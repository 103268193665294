import { useGesture } from '@use-gesture/react'
import classNames from 'classnames'
import Gridlines from 'components/gridlines'
import { TRACKS_EDITOR_ITEM_MARGIN_HORIZONTAL } from 'constants/tracks-editor-layout'
import useObjectKeyStore from 'hooks/use-object-key-store'
import { range } from 'lodash'
import { MFChord, MFNote, MFOctave, MFTrackItem } from 'music-file'
import { useMusicFileContext } from 'providers/music-file'
import { useMusicFilePlaybackContext } from 'providers/music-file-playback'
import { useMemo, useRef } from 'react'
import { extractHTMLElement } from '../../../helpers/extract-html-element'
import {
  computeTracksEditorLayout,
  estimateTrackItemInsertion,
} from '../../../helpers/tracks-editor'
import {
  TracksEditorSelection,
  useTracksEditorContext,
} from '../../../providers/tracks-editor'
import TrackItem from '../track-item'
import styles from './index.module.scss'

type DragMode = 'move' | 'expand'

const getDOMElement = (trackNum: number, pos: number) => {
  return document.querySelector(
    `[data-type="track"][data-track-num="${trackNum}"] [data-type="trackItem"][data-pos="${pos}"]`,
  ) as HTMLDivElement
}

export interface TrackCanvasProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'children'> {}

const TrackCanvas = ({ className, ...props }: TrackCanvasProps) => {
  const { getObjectKey, replaceObjectKey } = useObjectKeyStore()

  const { musicFile, updateMusicFile } = useMusicFileContext()
  const { playTrackItem } = useMusicFilePlaybackContext()

  const { selections, insertType, setSelections, setInsertType } =
    useTracksEditorContext()

  const {
    canvasWidth,
    canvasHeight,
    unitWidth,
    gridWidth,
    gridHeight,
    numGridsPerBeat,
    numGridsPerBar,
    numTicksPerBeat,
    numTicksPerGrid,
  } = computeTracksEditorLayout(musicFile)

  const gridlines = useMemo(() => {
    return (
      <Gridlines
        className={styles.gridlines}
        canvasWidth={canvasWidth}
        canvasHeight={canvasHeight}
        pattern={{
          width: gridWidth * numGridsPerBar,
          height: gridHeight,
          lines: [
            {
              x1: 0,
              x2: '100%',
              y1: gridHeight,
              y2: gridHeight,
              stroke: '#ffffff35',
              strokeWidth: 1,
            },
            ...range(1, numGridsPerBar + 1).map(i => {
              const x = i * gridWidth
              const stroke =
                i % numGridsPerBar === 0
                  ? '#ffffff28'
                  : i % numGridsPerBeat === 0
                  ? '#ffffff12'
                  : '#ffffff06'

              return {
                x1: x,
                x2: x,
                y1: 0,
                y2: gridHeight,
                stroke,
                strokeWidth: 1,
              }
            }),
          ],
        }}
      />
    )
  }, [
    canvasHeight,
    canvasWidth,
    gridHeight,
    gridWidth,
    numGridsPerBar,
    numGridsPerBeat,
  ])

  const getMagneticTrackItems = (trackNum: number, pos: number) => {
    const track = musicFile.tracks.at(trackNum)
    const results: MFTrackItem[] = [track.items.at(pos)]

    for (const item of track.items.slice(pos + 1).toArray()) {
      if (!results.slice(-1)[0].isConsecutiveTo(item)) {
        break
      }

      results.push(item)
    }

    return results
  }

  const dragMode = useRef<DragMode>()
  const dragItemsRef = useRef<MFTrackItem[]>([])
  const dragValidMovementRef = useRef(0)

  const bind = useGesture({
    onClick: state => {
      if (dragMode.current) {
        return
      }

      const element = state.event.target as HTMLDivElement
      const extracted = extractHTMLElement(musicFile, element)

      switch (extracted.type) {
        case 'track': {
          const { track } = extracted

          const currentTarget = state.event.currentTarget as HTMLDivElement
          const rect = currentTarget.getBoundingClientRect()
          const dx = state.event.clientX - rect.x

          let octave: MFOctave | undefined
          let duration: number | undefined

          const lastSelectedTrackItem = selections
            .filter(MFTrackItem.is)
            .slice(-1)[0]

          if (lastSelectedTrackItem) {
            if ('octave' in lastSelectedTrackItem.source) {
              octave = lastSelectedTrackItem.source.octave
            }

            duration = lastSelectedTrackItem.duration
          }

          const insertion = estimateTrackItemInsertion({
            type: insertType,
            trackItems: track.items.toArray(),
            dx,
            gridWidth,
            numTicksPerGrid,
            numTicksPerBeat,
            octave,
            duration,
          })

          if (insertion) {
            updateMusicFile(actions => {
              actions.musicFile.tracks.select(track).items.insert(insertion)
              actions.musicFile.ensureMinValidNumBars()
            })

            return setSelections([insertion])
          }

          break
        }
        default:
      }
    },
    onPointerDown: state => {
      const element = state.event.target as HTMLDivElement
      const extracted = extractHTMLElement(musicFile, element)

      switch (extracted.type) {
        case 'trackItem': {
          const { trackNum, trackItem } = extracted

          if (state.event.button === 2) {
            return
          }

          switch (trackItem.sourceType) {
            case MFNote:
              setInsertType('note')
              break
            case MFChord:
              setInsertType('chord')
              break
            default:
          }

          if (state.ctrlKey) {
            if (selections.includes(trackItem)) {
              setSelections(selections =>
                selections.filter(value => value !== trackItem),
              )
            } else {
              setSelections(selections => selections.concat(trackItem))
            }
          } else if (state.shiftKey) {
            const locations = selections
              .filter(MFTrackItem.is)
              .concat(trackItem)
              .map(item => [musicFile.locate(item)[0], item.begin])
              .sort((a, b) => a[0] - b[0] || a[1] - b[1])

            const first = locations[0]
            const last = locations[locations.length - 1]

            const pending: TracksEditorSelection[] = []

            range(first[0], last[0] + 1).forEach(trackNum => {
              range(first[1], last[1] + 1).forEach(begin => {
                const item = musicFile.tracks
                  .at(trackNum)
                  .items.toArray()
                  .find(item => item.begin === begin)

                if (item) {
                  pending.push(item)
                }
              })
            })

            setSelections(pending)
          } else {
            setSelections([trackItem])
          }

          return playTrackItem(trackNum, trackItem)
        }
        default:
      }
    },
    onDragStart: state => {
      if (dragMode.current) {
        return
      }

      const element = state.event.target as HTMLDivElement
      const extracted = extractHTMLElement(musicFile, element)

      switch (extracted.type) {
        case 'trackItem': {
          const { trackNum, pos } = extracted

          const rect = element.getBoundingClientRect()
          const distance = rect.right - state.xy[0]

          if (distance < 15) {
            dragMode.current = 'expand'
          } else {
            dragMode.current = 'move'
          }

          dragItemsRef.current = getMagneticTrackItems(trackNum, pos)
          dragValidMovementRef.current = 0

          break
        }
      }
    },
    onDragEnd: () => {
      if (!dragMode.current) {
        return
      }

      const dragItems = dragItemsRef.current
      const dx = dragValidMovementRef.current
      const grids = Math.round(dx / gridWidth)
      const ticks = numTicksPerGrid * grids

      const [trackNum, pos] = musicFile.locate(dragItems[0])

      const release = () => {
        requestAnimationFrame(() => {
          delete dragMode.current
        })
      }

      const scheduleTransitionEnd = async (cb: () => void) => {
        const promises: Promise<void>[] = []

        for (let i = 0; i < dragItems.length; i++) {
          const element = getDOMElement(trackNum, pos + i)

          promises.push(
            new Promise(resolve => {
              const handler = () => {
                element.removeEventListener('transitionend', handler)
                resolve()
              }

              element.addEventListener('transitionend', handler)
            }),
          )
        }

        await Promise.all(promises)

        cb()
        release()
      }

      switch (dragMode.current) {
        case 'move': {
          for (let i = 0; i < dragItems.length; i++) {
            const element = getDOMElement(trackNum, pos + i)
            const item = dragItems[i]
            const translateX = unitWidth * (item.begin + ticks)

            element.style.transition = 'transform 150ms, width 150ms'
            element.style.transform = `translateX(${translateX}px) translate3d(0, 0, 0)`
          }

          if (Math.abs(ticks) > 0) {
            scheduleTransitionEnd(() => {
              const updatedMusicFile = updateMusicFile(actions => {
                const updatedItems = dragItems.map(item => {
                  return actions.musicFile.tracks
                    .select(trackNum)
                    .items.select(item)
                    .update(item => ({
                      begin: item.begin + ticks,
                    }))
                })

                actions.musicFile.ensureMinValidNumBars()

                updatedItems.forEach((_, index) => {
                  replaceObjectKey(dragItems[index], updatedItems[index])
                })

                setSelections(selections =>
                  selections
                    .filter(value => !dragItems.some(item => item === value))
                    .concat(updatedItems[0]),
                )
              })

              replaceObjectKey(
                musicFile.tracks.at(trackNum),
                updatedMusicFile.tracks.at(trackNum),
              )
            })
          } else {
            release()
          }

          break
        }
        case 'expand': {
          const element = getDOMElement(trackNum, pos)
          const item = dragItems[0]
          const width = unitWidth * (item.duration + ticks)

          element.style.transition = 'transform 150ms, width 150ms'
          element.style.width = `${
            width - TRACKS_EDITOR_ITEM_MARGIN_HORIZONTAL * 2
          }px`

          for (let i = 1; i < dragItems.length; i++) {
            const element = getDOMElement(trackNum, pos + i)
            const item = dragItems[i]
            const translateX = unitWidth * (item.begin + ticks)

            element.style.transition = 'transform 150ms, width 150ms'
            element.style.transform = `translateX(${translateX}px) translate3d(0, 0, 0)`
          }

          if (Math.abs(ticks) > 0) {
            scheduleTransitionEnd(() => {
              const updatedMusicFile = updateMusicFile(actions => {
                const updatedItems = [
                  actions.musicFile.tracks
                    .select(trackNum)
                    .items.select(dragItems[0])
                    .update(item => ({
                      duration: item.duration + ticks,
                    })),
                  ...dragItems.slice(1).map(item => {
                    return actions.musicFile.tracks
                      .select(trackNum)
                      .items.select(item)
                      .update(item => ({
                        begin: item.begin + ticks,
                      }))
                  }),
                ]

                actions.musicFile.ensureMinValidNumBars()

                updatedItems.forEach((_, index) => {
                  replaceObjectKey(dragItems[index], updatedItems[index])
                })

                setSelections(selections =>
                  selections
                    .filter(value => !dragItems.some(item => item === value))
                    .concat(updatedItems[0]),
                )
              })

              replaceObjectKey(
                musicFile.tracks.at(trackNum),
                updatedMusicFile.tracks.at(trackNum),
              )
            })
          } else {
            release()
          }

          break
        }
      }
    },
    onDrag: state => {
      if (!dragMode.current) {
        return
      }

      const element = state.event.target as HTMLDivElement
      const extracted = extractHTMLElement(musicFile, element)

      switch (extracted.type) {
        case 'trackItem': {
          const { trackNum, track, trackItem, pos } = extracted

          const dragItems = dragItemsRef.current
          const dx = state.movement[0]
          const grids = Math.round(dx / gridWidth)
          const ticks = numTicksPerGrid * grids

          switch (dragMode.current) {
            case 'move': {
              if (dx < 0) {
                const prev = track.items.at(pos - 1)

                if (
                  trackItem.begin + ticks < 0 ||
                  (prev && prev.end > trackItem.begin + ticks)
                ) {
                  return
                }
              } else if (dx > 0) {
                const dragLast = dragItems[dragItems.length - 1]
                const dragLastPos = track.items.indexOf(dragLast)
                const next = track.items.at(dragLastPos + 1)

                if (next && next.begin < dragLast.end + ticks) {
                  return
                }
              }

              dragValidMovementRef.current = dx

              for (let i = 0; i < dragItems.length; i++) {
                const element = getDOMElement(trackNum, pos + i)
                const item = dragItems[i]
                const translateX = unitWidth * item.begin + dx

                element.style.transition = 'none'
                element.style.transform = `translateX(${translateX}px) translate3d(0, 0, 0)`
              }

              break
            }
            case 'expand': {
              if (dx < 0) {
                if (trackItem.duration + ticks < numTicksPerGrid) {
                  return
                }
              } else if (dx > 0) {
                const dragLast = dragItems[dragItems.length - 1]
                const dragLastPos = track.items.indexOf(dragLast)
                const next = track.items.at(dragLastPos + 1)

                if (next && next.begin < dragLast.end + ticks) {
                  return
                }
              }

              dragValidMovementRef.current = dx

              const element = getDOMElement(trackNum, pos)
              const item = dragItems[0]
              const width = unitWidth * item.duration + dx

              element.style.transition = 'none'
              element.style.width = `${
                width - TRACKS_EDITOR_ITEM_MARGIN_HORIZONTAL * 2
              }px`

              for (let i = 1; i < dragItems.length; i++) {
                const element = getDOMElement(trackNum, pos + i)
                const item = dragItems[i]
                const translateX = unitWidth * item.begin + dx

                element.style.transition = 'none'
                element.style.transform = `translateX(${translateX}px) translate3d(0, 0, 0)`
              }

              break
            }
          }

          break
        }
        default:
      }
    },
  })

  return (
    <div
      className={classNames(styles.trackCanvas, className)}
      style={{
        width: canvasWidth,
        height: canvasHeight,
      }}
      {...props}
    >
      {gridlines}
      <div className={styles.tracks} {...bind()}>
        {musicFile.tracks.toArray().map((track, trackNum) => (
          <div
            key={getObjectKey(track)}
            className={classNames(styles.track)}
            style={{ height: gridHeight }}
            data-type="track"
            data-track-num={trackNum}
          >
            {track.items.toArray().map((item, pos) => (
              <TrackItem
                key={getObjectKey(item)}
                unitWidth={unitWidth}
                item={item}
                pos={pos}
                selected={selections.includes(item)}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export default TrackCanvas
