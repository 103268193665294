import { ensureValidNoteName } from './note.js';
export class MFInstrument {
    constructor({ name, resourceURI, unpitched = false, noteNameMapping = {}, }) {
        this.name = name;
        this.resourceURI = resourceURI;
        this.unpitched = unpitched;
        this.noteNameMapping = noteNameMapping;
    }
    static is(other) {
        return other instanceof MFInstrument;
    }
    static fromJSON(json) {
        if (json.__type !== 'instrument') {
            throw new Error('invalid instrument json type');
        }
        return new MFInstrument({
            name: json.name,
            resourceURI: json.resourceURI,
            unpitched: json.unpitched,
            noteNameMapping: json.noteNameMapping,
        });
    }
    equals(other) {
        return (other instanceof MFInstrument &&
            other.name === this.name &&
            other.resourceURI === this.resourceURI &&
            other.unpitched === this.unpitched &&
            Object.keys(other.noteNameMapping)
                .map(ensureValidNoteName)
                .every(key => this.noteNameMapping[key] === other.noteNameMapping[key]));
    }
    copy({ name, resourceURI, unpitched, noteNameMapping, } = {}) {
        return new MFInstrument({
            name: name !== null && name !== void 0 ? name : this.name,
            resourceURI: resourceURI !== null && resourceURI !== void 0 ? resourceURI : this.resourceURI,
            unpitched: unpitched !== null && unpitched !== void 0 ? unpitched : this.unpitched,
            noteNameMapping: noteNameMapping !== null && noteNameMapping !== void 0 ? noteNameMapping : this.noteNameMapping,
        });
    }
    toJSON() {
        return {
            __type: 'instrument',
            name: this.name,
            resourceURI: this.resourceURI,
            unpitched: this.unpitched,
            noteNameMapping: this.noteNameMapping,
        };
    }
}
