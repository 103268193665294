export class MFSampleManager {
    constructor() {
        this.collection = {};
    }
    addInstrument(instrumentURI, sampleMap) {
        this.collection[instrumentURI] = sampleMap;
    }
    addSample(instrumentURI, sampleURI, sample) {
        var _a;
        var _b;
        (_a = (_b = this.collection)[instrumentURI]) !== null && _a !== void 0 ? _a : (_b[instrumentURI] = {});
        this.collection[instrumentURI][sampleURI] = sample;
    }
    deleteInstrument(instrumentURI) {
        if (instrumentURI in this.collection) {
            delete this.collection[instrumentURI];
        }
    }
    deleteSample(instrumentURI, sampleURI) {
        if (instrumentURI in this.collection) {
            delete this.collection[instrumentURI][sampleURI];
        }
    }
    hasInstrumentURI(instrumentURI) {
        return instrumentURI in this.collection;
    }
    hasSampleURI(instrumentURI, sampleURI) {
        return (instrumentURI in this.collection &&
            sampleURI in this.collection[instrumentURI]);
    }
    getInstrumentURIs() {
        return Object.keys(this.collection);
    }
    getSampleURIs(instrumentURI) {
        if (!this.hasInstrumentURI(instrumentURI)) {
            return [];
        }
        return Object.keys(this.collection[instrumentURI]);
    }
    resolveSample(instrumentURI, sampleURI) {
        if (!this.hasSampleURI(instrumentURI, sampleURI)) {
            throw new Error('sample is not found');
        }
        return this.collection[instrumentURI][sampleURI];
    }
}
