import { getChordPitches, getNotePitch } from './note-pitch.js';
export class MFSampleNode {
    constructor(ctx, sample, volume, { onSoundOn, onSoundOff, } = {}) {
        this.ctx = ctx;
        this.sample = sample;
        this.volume = volume;
        this.onSoundOn = onSoundOn;
        this.onSoundOff = onSoundOff;
        this.sourceNode = ctx.createBufferSource();
        this.gainNode = ctx.createGain();
        this.sourceNode.connect(this.gainNode);
        this.gainNode.connect(this.ctx.destination);
        this.sourceNode.playbackRate.value = 1;
        this.sourceNode.buffer = sample.audioBuffer;
        this.sourceNode.onended = () => {
            this.gainNode.disconnect();
            this.sourceNode.disconnect();
        };
    }
    soundOn() {
        var _a;
        const now = this.ctx.currentTime;
        const gainValue = this.volume / 100;
        const { attackAmp, attackTime, decayAmp, decayTime, sustainAmp, sustainTime, } = this.sample.adsr;
        this.gainNode.gain
            .linearRampToValueAtTime(gainValue * attackAmp, now + attackTime)
            .linearRampToValueAtTime(gainValue * decayAmp, now + attackTime + decayTime)
            .linearRampToValueAtTime(gainValue * sustainAmp, now + attackTime + sustainTime);
        this.sourceNode.start();
        (_a = this.onSoundOn) === null || _a === void 0 ? void 0 : _a.call(this, this);
    }
    soundOff() {
        var _a;
        const now = this.ctx.currentTime;
        const gainValue = this.gainNode.gain.value;
        const { releaseAmp, releaseTime } = this.sample.adsr;
        this.gainNode.gain.linearRampToValueAtTime(gainValue * releaseAmp, now + releaseTime);
        this.sourceNode.stop(now + releaseTime);
        (_a = this.onSoundOff) === null || _a === void 0 ? void 0 : _a.call(this, this);
    }
    setVolume(volume) {
        const now = this.ctx.currentTime;
        const gainValue = volume / 100;
        this.gainNode.gain.cancelScheduledValues(now).setValueAtTime(gainValue, now);
    }
}
export class MFSamplePlayer {
    constructor(ctx, manager) {
        this.ctx = ctx;
        this.manager = manager;
        this.playingSampleNodes = new Set();
    }
    playSample({ instrumentURI, sampleURI, volume = 100, durationMs = 0, }) {
        const sample = this.manager.resolveSample(instrumentURI, sampleURI);
        const sampleNode = new MFSampleNode(this.ctx, sample, volume, {
            onSoundOn: node => this.playingSampleNodes.add(node),
            onSoundOff: node => this.playingSampleNodes.delete(node),
        });
        sampleNode.soundOn();
        if (durationMs > 0) {
            setTimeout(() => sampleNode.soundOff(), durationMs);
        }
        return sampleNode;
    }
    playSampleByNote({ instrumentURI, note, key, volume = 100, durationMs = 0, sampleURIResolver = pitch => `sample:${pitch}`, }) {
        const pitch = getNotePitch(note, key);
        const sampleURI = sampleURIResolver(pitch);
        return this.playSample({
            instrumentURI,
            sampleURI,
            volume,
            durationMs,
        });
    }
    playSamplesByChord({ instrumentURI, chord, key, volume = 100, durationMs = 0, sampleURIResolver = pitch => `sample:${pitch}`, }) {
        return getChordPitches(chord, key).map(pitch => this.playSample({
            instrumentURI,
            sampleURI: sampleURIResolver(pitch),
            volume,
            durationMs,
        }));
    }
    stopAllSamples() {
        this.playingSampleNodes.forEach(node => node.soundOff());
    }
}
