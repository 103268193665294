import Dialog from 'components/dialog'
import { handleFullscreenLoading } from 'components/fullscreen-loading'
import { showModal } from 'components/modal'
import { MFInstrument, MFMusicFile } from 'music-file'
import { useMusicFileContext } from 'providers/music-file'
import { useMusicFilePlaybackContext } from 'providers/music-file-playback'
import { useState } from 'react'
import styles from './index.module.scss'

export interface ImportMusicFileDialogProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'children'> {
  onRequestClose?: () => void
}

const ImportMusicFileDialog = ({
  onRequestClose,
  ...props
}: ImportMusicFileDialogProps) => {
  const { setMusicFile } = useMusicFileContext()
  const { sampleManager, sampleLoader } = useMusicFilePlaybackContext()

  const [file, setFile] = useState<File>()

  return (
    <Dialog
      onRequestClose={async value => {
        if (value === 'import' && file) {
          const payload = await file.text()
          const musicFile = MFMusicFile.fromJSON(JSON.parse(payload))

          const requiredInstruments: MFInstrument[] = []

          for (const track of musicFile.tracks.toArray()) {
            const { instrument } = track

            if (!sampleManager.hasInstrumentURI(instrument.resourceURI)) {
              requiredInstruments.push(instrument)
            }
          }

          if (requiredInstruments.length > 0) {
            await handleFullscreenLoading(() =>
              Promise.all(
                requiredInstruments.map(instrument => {
                  const [, , codename] = instrument.resourceURI.split(':')

                  return sampleLoader.loadInstrumentFromURL(
                    instrument.resourceURI,
                    `https://sounds-cdn.improvising.io/${codename}`,
                  )
                }),
              ),
            )
          }

          setMusicFile(musicFile)
        }

        onRequestClose?.()
      }}
      title="Import MusicFile"
      content={
        <div className={styles.container}>
          <input
            className={styles.input}
            type="file"
            accept=".json"
            onInput={event => setFile(event.currentTarget.files?.[0])}
          />
        </div>
      }
      buttons={[
        { value: 'cancel' as const, title: 'Cancel', variant: 'secondary' },
        { value: 'import' as const, title: 'Import', variant: 'primary' },
      ]}
      minWidth={400}
      {...props}
    />
  )
}

export const showImportMusicFileDialog = () => {
  return new Promise<void>(resolve => {
    showModal<void>({
      children: ({ dismiss }) => (
        <ImportMusicFileDialog onRequestClose={dismiss} />
      ),
      onClose: () => resolve(),
      onRequestClose: ({ dismiss }) => dismiss(),
      transition: 'fadeInOutBottom',
    })
  })
}

export default ImportMusicFileDialog
