import classNames from 'classnames'
import { showModal } from 'components/modal'
import styles from './index.module.scss'

export interface PopupMenuItemProps<T> {
  value: T
  title: string
  destructive?: boolean
  onClick?: () => void
}

export interface PopupMenuProps<T>
  extends Omit<React.HTMLAttributes<HTMLElement>, 'children'> {
  items: PopupMenuItemProps<T>[]
  onRequestClose?: (value: T) => void
}

const PopupMenu = <T,>({
  className,
  items,
  onRequestClose,
  ...props
}: PopupMenuProps<T>) => {
  return (
    <div className={classNames(styles.popupMenu, className)} {...props}>
      {items.map(({ value, title, destructive, onClick }, index) => (
        <div
          key={index}
          className={classNames(styles.item, {
            [styles.destructive]: destructive,
          })}
          onClick={() => {
            onClick?.()
            onRequestClose?.(value)
          }}
        >
          {title}
        </div>
      ))}
    </div>
  )
}

export interface ShowPopupMenuProps<T>
  extends Omit<PopupMenuProps<T>, 'onRequestClose'> {
  position: {
    x: number
    y: number
  }
}

export const showPopupMenu = <T,>({
  position: { x, y },
  ...props
}: ShowPopupMenuProps<T>) => {
  return new Promise<Nullable<T>>(resolve => {
    showModal<T>({
      children: ({ dismiss }) => {
        return <PopupMenu onRequestClose={dismiss} {...props} />
      },
      onRequestClose: ({ dismiss }) => dismiss(),
      onClose: resolve,
      transition: 'fadeInOutLeft',
      style: {
        position: 'absolute',
        left: x,
        top: y,
      },
      barrierStyle: {
        backgroundColor: 'transparent',
      },
    })
  })
}

export default PopupMenu
