import classNames from 'classnames'
import { handleFullscreenLoading } from 'components/fullscreen-loading'
import Gridlines from 'components/gridlines'
import { PopupMenuItemProps, showPopupMenu } from 'components/popup-menu'
import { DEFAULT_INSTRUMENT } from 'constants/instrument-collection'
import { TRACKS_EDITOR_HEADER_WIDTH } from 'constants/tracks-editor-layout'
import useObjectKeyStore from 'hooks/use-object-key-store'
import { MFTrack } from 'music-file'
import { useMusicFileContext } from 'providers/music-file'
import { useMusicFilePlaybackContext } from 'providers/music-file-playback'
import { useMemo } from 'react'
import { computeTracksEditorLayout } from '../../../helpers/tracks-editor'
import { useTracksEditorContext } from '../../../providers/tracks-editor'
import { showInstrumentCollectionModal } from '../../instrument-collection-modal'
import TrackHeader from '../track-header'
import styles from './index.module.scss'

export interface TrackHeadersProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'children'> {}

const TrackHeaders = ({ className }: TrackHeadersProps) => {
  const { getObjectKey } = useObjectKeyStore()

  const { selections, setSelections } = useTracksEditorContext()
  const { musicFile, updateMusicFile } = useMusicFileContext()
  const { sampleManager, sampleLoader } = useMusicFilePlaybackContext()

  const { tracks } = musicFile
  const { canvasHeight, gridHeight } = computeTracksEditorLayout(musicFile)

  const showTrackHandlePopupMenu = async (
    position: {
      x: number
      y: number
    },
    trackNum: number,
  ) => {
    const items: PopupMenuItemProps<string>[] = []

    items.push({
      value: 'createTrack',
      title: 'New Track',
    })

    if (trackNum > 0) {
      items.push({
        value: 'moveTrackUp',
        title: 'Move Up',
      })
    }

    if (trackNum < tracks.length - 1) {
      items.push({
        value: 'moveTrackDown',
        title: 'Move Down',
      })
    }

    items.push({
      value: 'deleteTrack',
      title: 'Delete',
      destructive: true,
    })

    const result = await showPopupMenu({ items, position })

    if (result) {
      switch (result) {
        case 'createTrack':
          return updateMusicFile(actions => {
            actions.musicFile.tracks.insertAt(
              trackNum + 1,
              new MFTrack({
                name: 'Track',
                instrument: DEFAULT_INSTRUMENT,
              }),
            )
          })
        case 'moveTrackUp':
        case 'moveTrackDown': {
          const diff = result === 'moveTrackUp' ? -1 : 1

          updateMusicFile(actions => {
            actions.musicFile.tracks.swap(trackNum, trackNum + diff)
          })

          break
        }
        case 'deleteTrack': {
          setSelections([])

          return updateMusicFile(actions => {
            actions.musicFile.tracks.select(trackNum).delete()
          })
        }
      }
    }
  }

  const gridlines = useMemo(() => {
    return (
      <Gridlines
        className={styles.gridlines}
        canvasWidth={TRACKS_EDITOR_HEADER_WIDTH}
        canvasHeight={canvasHeight}
        pattern={{
          width: '100%',
          height: gridHeight,
          lines: [
            {
              x1: 0,
              x2: '100%',
              y1: gridHeight,
              y2: gridHeight,
              stroke: '#ffffff35',
              strokeWidth: 1,
            },
          ],
        }}
      />
    )
  }, [canvasHeight, gridHeight])

  return (
    <div
      className={classNames(styles.trackHeaders, className)}
      style={{
        width: TRACKS_EDITOR_HEADER_WIDTH,
        height: canvasHeight,
      }}
    >
      {gridlines}
      <div className={styles.headers}>
        {tracks.toArray().map((track, trackNum) => (
          <TrackHeader
            key={getObjectKey(track)}
            track={track}
            selected={selections.includes(track)}
            onClick={() => {
              if (!selections.includes(track)) {
                setSelections([track])
              }
            }}
            onHandleClick={event => {
              showTrackHandlePopupMenu(
                {
                  x: event.clientX + 8,
                  y: event.clientY - 16,
                },
                trackNum,
              )

              return setSelections([track])
            }}
            onInstrumentClick={() => {
              showInstrumentCollectionModal({
                initial: track.instrument,
                onInstrumentChange: async instrument => {
                  if (!sampleManager.hasInstrumentURI(instrument.resourceURI)) {
                    const [, , codename] = instrument.resourceURI.split(':')

                    await handleFullscreenLoading(() =>
                      sampleLoader.loadInstrumentFromURL(
                        instrument.resourceURI,
                        `https://sounds-cdn.improvising.io/${codename}`,
                      ),
                    )
                  }

                  updateMusicFile(actions => {
                    const updatedTrack = actions.musicFile.tracks
                      .select(trackNum)
                      .update({ instrument })

                    setSelections(selections =>
                      selections
                        .filter(value => value !== track)
                        .concat(updatedTrack),
                    )
                  })
                },
              })
            }}
            onNameChange={name => {
              updateMusicFile(actions => {
                const updatedTrack = actions.musicFile.tracks
                  .select(trackNum)
                  .update({ name })

                setSelections([updatedTrack])
              })
            }}
            onVolumeChange={volume => {
              updateMusicFile(actions => {
                const updatedTrack = actions.musicFile.tracks
                  .select(trackNum)
                  .update({ volume })

                setSelections([updatedTrack])
              })
            }}
            onMutedChange={muted => {
              updateMusicFile(actions => {
                const updatedTrack = actions.musicFile.tracks
                  .select(trackNum)
                  .update({ muted })

                setSelections([updatedTrack])
              })
            }}
          />
        ))}
      </div>
    </div>
  )
}

export default TrackHeaders
