export const MUSIC_FILE_SIGNATURE_TUPLES = [
    [2, 2],
    [2, 4],
    [3, 4],
    [4, 4],
    [6, 8],
    [9, 8],
    [12, 8],
];
export class MFSignature {
    constructor(numBeats, beatNoteType) {
        this.numBeats = numBeats;
        this.beatNoteType = beatNoteType;
    }
    static is(other) {
        return other instanceof MFSignature;
    }
    static fromJSON(json) {
        if (json.__type !== 'signature') {
            throw new Error('invalid signature json type');
        }
        return new MFSignature(ensureValidSignatureNumBeats(json.numBeats), ensureValidSignatureBeatNoteType(json.beatNoteType));
    }
    get tuple() {
        return [this.numBeats, this.beatNoteType];
    }
    equals(other) {
        return (other instanceof MFSignature &&
            other.numBeats == this.numBeats &&
            other.beatNoteType === this.beatNoteType);
    }
    copy({ numBeats, beatNoteType, } = {}) {
        return new MFSignature(numBeats !== null && numBeats !== void 0 ? numBeats : this.numBeats, beatNoteType !== null && beatNoteType !== void 0 ? beatNoteType : this.beatNoteType);
    }
    toJSON() {
        return {
            __type: 'signature',
            numBeats: this.numBeats,
            beatNoteType: this.beatNoteType,
        };
    }
}
export const isValidSignatureNumBeats = (x) => {
    return MUSIC_FILE_SIGNATURE_TUPLES.some(tuple => tuple[0] === x);
};
export const ensureValidSignatureNumBeats = (x) => {
    if (!isValidSignatureNumBeats(x)) {
        throw new Error(`${x} is not a valid signature numBeats`);
    }
    return x;
};
export const isValidSignatureBeatNoteType = (x) => {
    return MUSIC_FILE_SIGNATURE_TUPLES.some(tuple => tuple[1] === x);
};
export const ensureValidSignatureBeatNoteType = (x) => {
    if (!isValidSignatureBeatNoteType(x)) {
        throw new Error(`${x} is not a valid signature beatNoteType`);
    }
    return x;
};
