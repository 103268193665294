import {
  TRACKS_EDITOR_BASE_BAR_WIDTH,
  TRACKS_EDITOR_GRID_HEIGHT,
  TRACKS_EDITOR_GRID_MIN_WIDTH,
} from 'constants/tracks-editor-layout'
import { MFChord, MFMusicFile, MFNote, MFOctave, MFTrackItem } from 'music-file'

export const computeTracksEditorLayout = (
  musicFile: MFMusicFile,
  {
    scaleX = 1.0,
    scaleY = 1.0,
  }: {
    scaleX?: number
    scaleY?: number
  } = {},
) => {
  const {
    signature,
    unitNoteType,
    numBars,
    numTicksPerBeat,
    numTicksPerBar,
    numTicks,
    tickMs,
  } = musicFile

  const { numBeats, beatNoteType } = signature

  const barWidth = scaleX * TRACKS_EDITOR_BASE_BAR_WIDTH
  const beatWidth = barWidth / numBeats

  // width and height of a unit note
  const unitWidth = barWidth / (numBeats * numTicksPerBeat)
  const unitHeight = scaleY * TRACKS_EDITOR_GRID_HEIGHT

  const numGridsPerBeat = Math.min(
    numTicksPerBeat,
    Math.pow(
      2,
      Math.floor(Math.log2(beatWidth / TRACKS_EDITOR_GRID_MIN_WIDTH)),
    ),
  )

  const numGridsPerBar = numGridsPerBeat * numBeats
  const numGrids = numGridsPerBar * numBars

  const numTicksPerGrid = numTicksPerBeat / numGridsPerBeat

  // smallest grid unit width that was shown
  const gridWidth = beatWidth / numGridsPerBeat
  const gridHeight = unitHeight

  // note type of grid
  const gridNoteType = Math.floor(unitNoteType / numGridsPerBeat)

  const canvasWidth = barWidth * numBars
  const canvasHeight = gridHeight * musicFile.tracks.length

  return {
    barWidth,
    canvasWidth,
    canvasHeight,
    numBeats,
    beatNoteType,
    unitNoteType,
    numBars,
    numTicksPerBeat,
    numTicksPerBar,
    numTicks,
    tickMs,
    beatWidth,
    unitWidth,
    unitHeight,
    gridWidth,
    gridHeight,
    gridNoteType,
    numGridsPerBeat,
    numGridsPerBar,
    numGrids,
    numTicksPerGrid,
  }
}

export const estimateTrackItemInsertion = ({
  type,
  trackItems,
  dx,
  gridWidth,
  numTicksPerGrid,
  numTicksPerBeat,
  octave,
  duration: customDuration,
}: {
  type: 'note' | 'chord'
  trackItems: readonly MFTrackItem[]
  dx: number
  gridWidth: number
  numTicksPerGrid: number
  numTicksPerBeat: number
  octave?: MFOctave
  duration?: number
}) => {
  const begin = Math.floor(dx / gridWidth) * numTicksPerGrid
  const duration = customDuration ?? numTicksPerBeat

  let insertion: MFTrackItem =
    type === 'note'
      ? new MFTrackItem({
          source: new MFNote('do', octave ?? 4),
          begin,
          duration,
        })
      : new MFTrackItem({
          source: new MFChord('I', octave ?? 3),
          begin,
          duration,
        })

  const overlap = trackItems.find(x => x.isTimeOverlappedWith(insertion))

  if (overlap) {
    if (overlap.begin <= insertion.begin) {
      return null
    }

    insertion = insertion.copy({
      duration: overlap.begin - insertion.begin,
    })
  }

  return insertion
}
