import { MFInstrument } from './instrument.js';
import { MFTrackItemArray } from './track-item.js';
export class MFTrack {
    constructor({ name, instrument, volume = 100, solo = false, muted = false, items, }) {
        this.name = name;
        this.instrument = instrument;
        this.volume = volume;
        this.solo = solo;
        this.muted = muted;
        this.items = items !== null && items !== void 0 ? items : new MFTrackItemArray();
    }
    static is(other) {
        return other instanceof MFTrack;
    }
    static fromJSON(json) {
        if (json.__type !== 'track') {
            throw new Error('invalid track json type');
        }
        return new MFTrack({
            name: json.name,
            instrument: MFInstrument.fromJSON(json.instrument),
            volume: json.volume,
            solo: json.solo,
            muted: json.muted,
            items: MFTrackItemArray.fromJSON(json.items),
        });
    }
    equals(other) {
        return (other instanceof MFTrack &&
            other.name === this.name &&
            other.instrument.equals(this.instrument) &&
            other.volume === this.volume &&
            other.solo === this.solo &&
            other.muted === this.muted &&
            other.items.equals(this.items));
    }
    copy({ name, instrument, volume, solo, muted, items, }) {
        return new MFTrack({
            name: name !== null && name !== void 0 ? name : this.name,
            instrument: instrument !== null && instrument !== void 0 ? instrument : this.instrument,
            volume: volume !== null && volume !== void 0 ? volume : this.volume,
            solo: solo !== null && solo !== void 0 ? solo : this.solo,
            muted: muted !== null && muted !== void 0 ? muted : this.muted,
            items: items !== null && items !== void 0 ? items : this.items,
        });
    }
    toJSON() {
        return {
            __type: 'track',
            name: this.name,
            instrument: this.instrument.toJSON(),
            volume: this.volume,
            solo: this.solo,
            muted: this.muted,
            items: this.items.toJSON(),
        };
    }
}
export class MFTrackArray {
    constructor(tracks = []) {
        this.tracks = tracks;
    }
    static is(other) {
        return other instanceof MFTrackArray;
    }
    static fromJSON(json) {
        return new MFTrackArray(json.map(MFTrack.fromJSON));
    }
    static fromArray(items) {
        return new MFTrackArray(items);
    }
    get length() {
        return this.tracks.length;
    }
    get isEmpty() {
        return this.length === 0;
    }
    get isNotEmpty() {
        return this.length > 0;
    }
    get first() {
        if (this.isEmpty) {
            throw new Error('no elements in the array');
        }
        return this.tracks[0];
    }
    get firstOrNull() {
        if (this.isEmpty) {
            throw null;
        }
        return this.tracks[0];
    }
    get last() {
        if (this.isEmpty) {
            throw new Error('no elements in the array');
        }
        return this.tracks[this.tracks.length - 1];
    }
    get lastOrNull() {
        if (this.isEmpty) {
            throw null;
        }
        return this.tracks[this.tracks.length - 1];
    }
    has(target) {
        if (typeof target === 'function') {
            return this.tracks.some(target);
        }
        return this.tracks.includes(target);
    }
    indexOf(target) {
        if (typeof target === 'function') {
            return this.tracks.findIndex(target);
        }
        return this.tracks.indexOf(target);
    }
    at(index) {
        return this.tracks[index];
    }
    slice(start, end) {
        return new MFTrackArray(this.tracks.slice(start, end));
    }
    insert(track) {
        return new MFTrackArray(this.tracks.concat(track));
    }
    insertAt(index, track) {
        return new MFTrackArray(this.tracks
            .slice(0, index)
            .concat(track)
            .concat(this.tracks.slice(index)));
    }
    delete(track) {
        return new MFTrackArray(this.tracks.filter(value => value !== track));
    }
    deleteAt(index) {
        return new MFTrackArray(this.tracks.slice(0, index).concat(this.tracks.slice(index + 1)));
    }
    replace(source, replacer) {
        return new MFTrackArray(Object.assign(this.tracks.slice(), {
            [this.tracks.indexOf(source)]: replacer,
        }));
    }
    replaceAt(index, replacer) {
        return new MFTrackArray(Object.assign(this.tracks.slice(), {
            [index]: typeof replacer === 'function'
                ? replacer(this.tracks[index])
                : replacer,
        }));
    }
    swap(a, b) {
        return new MFTrackArray(Object.assign(this.tracks.slice(), {
            [a]: this.tracks[b],
            [b]: this.tracks[a],
        }));
    }
    equals(other) {
        return (other instanceof MFTrackArray &&
            other.tracks.length === this.tracks.length &&
            other.tracks.every((val, index) => val === this.tracks[index]));
    }
    toArray() {
        return this.tracks;
    }
    toJSON() {
        return this.tracks.map(track => track.toJSON());
    }
}
