import classNames from 'classnames'
import { useMusicFileContext } from 'providers/music-file'
import styles from './index.module.scss'

export interface StatusBarProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'children'> {}

const StatusBar = ({ className, ...props }: StatusBarProps) => {
  const { musicFile } = useMusicFileContext()
  const { key, bpm, numTicks, tickMs } = musicFile

  const duration = Math.floor(numTicks * tickMs) / 1000

  return (
    <div className={classNames(styles.statusBar, className)} {...props}>
      <div className={styles.item}>Key: {key.name}</div>
      <div className={styles.item}>BPM: {bpm}</div>
      <div className={styles.item}>Duration: {duration}s</div>
    </div>
  )
}

export default StatusBar
