export interface PortalInstance {
  createNode: (nodeId: string, element: React.ReactNode) => void
  removeNode: (nodeId: string) => void
}

export const portalInstanceMap = new Map<string, PortalInstance>()

export const getPortalInstance = (portalId?: string) => {
  let instance: PortalInstance | undefined

  if (portalId) {
    instance = portalInstanceMap.get(portalId)
  } else {
    instance = Array.from(portalInstanceMap.values()).slice(-1)[0]
  }

  if (instance === undefined) {
    throw new Error('PortalHost is required to be in the tree')
  }

  return instance
}

export const createPortalNode = (
  node: React.ReactNode,
  { portalId }: { portalId?: string } = {},
) => {
  const portalInstance = getPortalInstance(portalId)
  const nodeId = Date.now().toString()

  portalInstance.createNode(nodeId, node)

  return () => portalInstance.removeNode(nodeId)
}
