import { MFTrack, MFTrackItem } from 'music-file'
import { createContext, PropsWithChildren, useContext, useState } from 'react'

export type TracksEditorSelection = MFTrack | MFTrackItem
export type TracksEditorInsertType = 'note' | 'chord'

export interface TracksEditorContext {
  selections: TracksEditorSelection[]
  insertType: TracksEditorInsertType
  setSelections: SetStateDispatch<TracksEditorSelection[]>
  setInsertType: SetStateDispatch<TracksEditorInsertType>
}

const context = createContext({} as TracksEditorContext)

const { Provider } = context

export const useTracksEditorContext = () => useContext(context)

export const TracksEditorProvider = ({ children }: PropsWithChildren) => {
  const [selections, setSelections] = useState<TracksEditorSelection[]>([])
  const [insertType, setInsertType] = useState<TracksEditorInsertType>('note')

  const value: TracksEditorContext = {
    selections,
    insertType,
    setSelections,
    setInsertType,
  }

  return <Provider value={value}>{children}</Provider>
}
