import { ensureValidOctave, MUSIC_FILE_MAX_OCTAVE, MUSIC_FILE_MIN_OCTAVE, } from './octave.js';
export const MUSIC_FILE_NOTE_NAMES = [
    'do',
    'do#',
    're',
    're#',
    'mi',
    'fa',
    'fa#',
    'sol',
    'sol#',
    'la',
    'la#',
    'ti',
];
export const MUSIC_FILE_NOTE_OCTAVE_SPAN = MUSIC_FILE_NOTE_NAMES.length;
export const MUSIC_FILE_MIN_NOTE_OFFSET = MUSIC_FILE_MIN_OCTAVE * MUSIC_FILE_NOTE_OCTAVE_SPAN;
export const MUSIC_FILE_MAX_NOTE_OFFSET = (MUSIC_FILE_MAX_OCTAVE + 1) * MUSIC_FILE_NOTE_OCTAVE_SPAN - 1;
export class MFNote {
    constructor(name, octave) {
        this.name = name;
        this.octave = octave;
    }
    static is(other) {
        return other instanceof MFNote;
    }
    static fromJSON(json) {
        if (json.__type !== 'note') {
            throw new Error('invalid note json type');
        }
        return new MFNote(ensureValidNoteName(json.name), ensureValidOctave(json.octave));
    }
    static fromOffset(offset) {
        const octave = Math.floor(offset / MUSIC_FILE_NOTE_OCTAVE_SPAN);
        const noteIndex = offset % MUSIC_FILE_NOTE_OCTAVE_SPAN;
        const noteName = MUSIC_FILE_NOTE_NAMES[noteIndex];
        return new MFNote(noteName, ensureValidOctave(octave));
    }
    get accidental() {
        return this.name.includes('#');
    }
    get offset() {
        const baseIndex = this.octave * MUSIC_FILE_NOTE_OCTAVE_SPAN;
        const index = MUSIC_FILE_NOTE_NAMES.indexOf(this.name);
        return baseIndex + index;
    }
    getUpper(steps = 1) {
        const offset = this.offset + steps;
        if (offset < MUSIC_FILE_MIN_NOTE_OFFSET ||
            offset > MUSIC_FILE_MAX_NOTE_OFFSET) {
            return this;
        }
        return MFNote.fromOffset(offset);
    }
    getLower(steps = 1) {
        const offset = this.offset - steps;
        if (offset < MUSIC_FILE_MIN_NOTE_OFFSET ||
            offset > MUSIC_FILE_MAX_NOTE_OFFSET) {
            return this;
        }
        return MFNote.fromOffset(offset);
    }
    equals(other) {
        return (other instanceof MFNote &&
            other.name === this.name &&
            other.octave === this.octave);
    }
    copy({ name, octave, } = {}) {
        return new MFNote(name !== null && name !== void 0 ? name : this.name, octave !== null && octave !== void 0 ? octave : this.octave);
    }
    toJSON() {
        return {
            __type: 'note',
            name: this.name,
            octave: this.octave,
        };
    }
}
export const isValidNoteName = (x) => {
    return MUSIC_FILE_NOTE_NAMES.includes(x);
};
export const ensureValidNoteName = (x) => {
    if (!isValidNoteName(x)) {
        throw new Error(`${x} is not a valid note name`);
    }
    return x;
};
