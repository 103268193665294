import useConst from 'hooks/use-const'
import {
  Fragment,
  memo,
  useId,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { PortalInstance, portalInstanceMap } from './portal-instance'

export interface PortalHostProps {
  id?: string
}

export const PortalHost = memo<PortalHostProps>(({ id }) => {
  const [nodeMap, setNodeMap] = useState(new Map<string, React.ReactNode>())

  const nodes = useMemo(() => {
    return Array.from(nodeMap.entries())
      .sort(([keyA], [keyB]) => {
        return keyA.localeCompare(keyB, 'en', { numeric: true })
      })
      .map(([key, node]) => <Fragment key={key}>{node}</Fragment>)
  }, [nodeMap])

  const uniqueId = useId()
  const portalId = useConst(() => id ?? uniqueId)

  useLayoutEffect(() => {
    const instance: PortalInstance = {
      createNode: (nodeId: string, node: React.ReactNode) => {
        setNodeMap(prevState => new Map(prevState).set(nodeId, node))
      },
      removeNode: (nodeId: string) => {
        setNodeMap(prevState => {
          const nextMap = new Map(prevState)
          nextMap.delete(nodeId)
          return nextMap
        })
      },
    }

    portalInstanceMap.set(portalId, instance)

    return () => {
      portalInstanceMap.delete(portalId)
    }
  }, [portalId])

  return <Fragment>{nodes}</Fragment>
})
