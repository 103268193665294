import { useNavigate } from 'react-router-dom'
import styles from './index.module.scss'

const Page = () => {
  const navigate = useNavigate()

  return (
    <div className={styles.page}>
      <img className={styles.logo} src="/logo.svg" alt="Improvising Studio" />
      <div className={styles.button} onClick={() => navigate('/workbench')}>
        Let's rock
      </div>
    </div>
  )
}

export default {
  path: '/',
  element: <Page />,
} as const
