import { MFNote, MUSIC_FILE_NOTE_NAMES } from './note.js';
import { ensureValidOctave, MUSIC_FILE_MAX_OCTAVE, } from './octave.js';
export const MUSIC_FILE_CHORD_NAMES = [
    'I',
    'II',
    'III',
    'IV',
    'V',
    'VI',
    'VII',
    'I7',
    'II7',
    'III7',
    'IV7',
    'V7',
    'VI7',
    'VII7',
    'i',
    'ii',
    'iii',
    'iv',
    'v',
    'vi',
    'vii',
    'vii-dim',
    'V/V',
    'i7',
    'ii7',
    'iii7',
    'iv7',
    'v7',
    'vi7',
    'vii7',
];
export const MUSIC_FILE_CHORD_NAME_NOTE_NAMES_MAP = {
    I: ['do', 'mi', 'sol'],
    II: ['re', 'fa#', 'la'],
    III: ['mi', 'sol#', 'ti'],
    IV: ['fa', 'la', 'do'],
    V: ['sol', 'ti', 're'],
    VI: ['la', 'do#', 'mi'],
    VII: ['ti', 're#', 'fa#'],
    I7: ['do', 'mi', 'sol', 'ti'],
    II7: ['re', 'fa#', 'la', 'do#'],
    III7: ['mi', 'sol#', 'ti', 're#'],
    IV7: ['fa', 'la', 'do', 'mi'],
    V7: ['sol', 'ti', 're', 'fa#'],
    VI7: ['la', 'do#', 'mi', 'sol#'],
    VII7: ['ti', 're#', 'fa#', 'la#'],
    i: ['do', 're#', 'sol'],
    ii: ['re', 'fa', 'la'],
    iii: ['mi', 'sol', 'ti'],
    iv: ['fa', 'sol#', 'do'],
    v: ['sol', 'la#', 're'],
    vi: ['la', 'do', 'mi'],
    vii: ['ti', 're', 'fa#'],
    'vii-dim': ['ti', 're', 'fa'],
    'V/V': ['re', 'fa#', 'la'],
    i7: ['do', 're#', 'sol', 'la#'],
    ii7: ['re', 'fa', 'la', 'do'],
    iii7: ['mi', 'sol', 'ti', 're'],
    iv7: ['fa', 'sol#', 'do', 're#'],
    v7: ['sol', 'la#', 're', 'fa'],
    vi7: ['la', 'do', 'mi', 'sol'],
    vii7: ['ti', 're', 'fa#', 'la'],
};
export class MFChord {
    constructor(name, octave) {
        this.name = name;
        this.octave = octave;
    }
    static is(other) {
        return other instanceof MFChord;
    }
    static fromJSON(json) {
        if (json.__type !== 'chord') {
            throw new Error('invalid chord json type');
        }
        return new MFChord(ensureValidChordName(json.name), ensureValidOctave(json.octave));
    }
    get notes() {
        const noteNames = MUSIC_FILE_CHORD_NAME_NOTE_NAMES_MAP[this.name];
        const notes = [];
        let octave = this.octave;
        let prevIndex = -1;
        for (const noteName of noteNames) {
            const index = MUSIC_FILE_NOTE_NAMES.indexOf(noteName);
            if (index < prevIndex) {
                octave = Math.min(octave + 1, MUSIC_FILE_MAX_OCTAVE);
            }
            prevIndex = index;
            notes.push(new MFNote(noteName, octave));
        }
        return notes;
    }
    get span() {
        const notes = this.notes;
        const upperBoundOffset = notes[notes.length - 1].offset;
        const lowerBoundOffset = notes[0].offset;
        return upperBoundOffset - lowerBoundOffset + 1;
    }
    equals(other) {
        return (other instanceof MFChord &&
            other.name === this.name &&
            other.octave === this.octave);
    }
    copy({ name, octave, } = {}) {
        return new MFChord(name !== null && name !== void 0 ? name : this.name, octave !== null && octave !== void 0 ? octave : this.octave);
    }
    toJSON() {
        return {
            __type: 'chord',
            name: this.name,
            octave: this.octave,
        };
    }
}
export const isValidChordName = (x) => {
    return MUSIC_FILE_CHORD_NAMES.includes(x);
};
export const ensureValidChordName = (x) => {
    if (!isValidChordName(x)) {
        throw new Error(`${x} is not a valid chord name`);
    }
    return x;
};
