export class MFRef {
    constructor({ name, typeURI, resourceURI, }) {
        this.name = name;
        this.typeURI = typeURI;
        this.resourceURI = resourceURI;
    }
    static is(other) {
        return other instanceof MFRef;
    }
    static fromJSON(json) {
        if (json.__type !== 'ref') {
            throw new Error('invalid ref json type');
        }
        return new MFRef({
            name: json.name,
            typeURI: json.typeURI,
            resourceURI: json.resourceURI,
        });
    }
    equals(other) {
        return (other instanceof MFRef &&
            other.name === this.name &&
            other.resourceURI === this.resourceURI);
    }
    copy({ name, typeURI, resourceURI, } = {}) {
        return new MFRef({
            name: name !== null && name !== void 0 ? name : this.name,
            typeURI: typeURI !== null && typeURI !== void 0 ? typeURI : this.typeURI,
            resourceURI: resourceURI !== null && resourceURI !== void 0 ? resourceURI : this.resourceURI,
        });
    }
    toJSON() {
        return {
            __type: 'ref',
            name: this.name,
            typeURI: this.typeURI,
            resourceURI: this.resourceURI,
        };
    }
}
