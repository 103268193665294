var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getPitchesBetween } from './note-pitch.js';
import { MFSample } from './sample.js';
export class MFSampleLoader {
    constructor(ctx, manager) {
        this.ctx = ctx;
        this.manager = manager;
    }
    loadSampleFromURL(instrumentURI, sampleURI, url) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield fetch(url);
            const arrayBuffer = yield res.arrayBuffer();
            const sampleBuffer = yield this.ctx.decodeAudioData(arrayBuffer);
            const sample = new MFSample(sampleBuffer);
            this.manager.addSample(instrumentURI, sampleURI, sample);
        });
    }
    loadInstrumentFromURL(instrumentURI, baseUrl, { pitchRange = ['A1', 'Gb7'], sampleFilenameResolver = pitch => `${pitch}.mp3`, sampleURIResolver = pitch => `sample:${pitch}`, } = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            yield Promise.all(getPitchesBetween(pitchRange[0], pitchRange[1]).map(pitch => {
                const sampleURI = sampleURIResolver(pitch);
                const base = baseUrl.endsWith('/') ? baseUrl : `${baseUrl}/`;
                const url = new URL(sampleFilenameResolver(pitch), base);
                return this.loadSampleFromURL(instrumentURI, sampleURI, url.href);
            }));
        });
    }
}
