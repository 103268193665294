import classNames from 'classnames'
// import Slider from 'components/slider'
import { ReactComponent as IconMoreVertical } from 'icons/more-vertical.svg'
// import { ReactComponent as IconVolumeUp } from 'icons/volume-up.svg'
// import { ReactComponent as IconVolumeOff } from 'icons/volume-off.svg'
import { MFTrack } from 'music-file'
import { useState } from 'react'
import styles from './index.module.scss'

export interface TrackHeaderProps {
  track: MFTrack
  selected?: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement>
  onHandleClick?: React.MouseEventHandler<HTMLDivElement>
  onInstrumentClick?: React.MouseEventHandler<HTMLDivElement>
  onNameChange?: (name: string) => void
  onVolumeChange?: (volume: number) => void
  onMutedChange?: (muted: boolean) => void
}

const TrackHeader = ({
  track,
  selected,
  onClick,
  onHandleClick,
  onInstrumentClick,
  onNameChange,
  onVolumeChange,
  onMutedChange,
}: TrackHeaderProps) => {
  const [name, setName] = useState(track.name)
  const [nameReadOnly, setNameReadOnly] = useState(true)

  const handleNameChangeCompletion = () => {
    if (nameReadOnly) {
      return
    }

    setNameReadOnly(true)

    if (name.length === 0) {
      setName(track.name)
    } else {
      onNameChange?.(name)
    }
  }

  return (
    <div
      className={classNames(styles.trackHeader, {
        [styles.selected]: selected,
      })}
      onClick={onClick}
    >
      <div className={styles.handle} onClick={onHandleClick}>
        <IconMoreVertical />
      </div>
      <div className={styles.info}>
        <input
          className={styles.name}
          readOnly={nameReadOnly}
          value={name}
          onChange={event => setName(event.target.value)}
          onDoubleClick={() => setNameReadOnly(false)}
          onBlur={handleNameChangeCompletion}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              handleNameChangeCompletion()
            }
          }}
        />
        <div className={styles.instrument} onClick={onInstrumentClick}>
          {track.instrument.name}
        </div>
        {/* <div className={styles.volume}>
          {track.muted ? (
            <IconVolumeOff
              className={styles.icon}
              onClick={() => onMutedChange?.(false)}
            />
          ) : (
            <IconVolumeUp
              className={styles.icon}
              onClick={() => onMutedChange?.(true)}
            />
          )}
          <Slider
            className={styles.slider}
            value={track.volume}
            maxValue={100}
            minValue={0}
            size={16}
            onValueChange={onVolumeChange}
          />
        </div> */}
      </div>
    </div>
  )
}

export default TrackHeader
