import classNames from 'classnames'
import { showModal } from 'components/modal'
import { INSTRUMENT_COLLECTION } from 'constants/instrument-collection'
import { useState } from 'react'
import styles from './index.module.scss'
import { ReactComponent as IconClose } from 'icons/close.svg'
import { MFInstrument } from 'music-file'

export interface InstrumentCollectionModalProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'children'> {
  initial?: MFInstrument
  onInstrumentChange?: (instrument: MFInstrument) => void
  onRequestClose?: () => void
}

const InstrumentCollectionModal = ({
  initial,
  className,
  onInstrumentChange,
  onRequestClose,
  ...props
}: InstrumentCollectionModalProps) => {
  const [selected, setSelected] = useState<MFInstrument | undefined>(initial)

  return (
    <div
      className={classNames(styles.instrumentCollectionModal, className)}
      {...props}
    >
      <div className={styles.head}>
        <div className={styles.title}>Instrument Collection</div>
        <IconClose className={styles.closeBtn} onClick={onRequestClose} />
      </div>
      <div className={styles.categories}>
        {INSTRUMENT_COLLECTION.map(item => (
          <div key={item.name} className={styles.category}>
            <div className={styles.name}>{item.name}</div>
            <div className={styles.instruments}>
              {item.instruments.map(instrument => (
                <div
                  key={instrument.resourceURI}
                  className={classNames(styles.instrument, {
                    [styles.selected]: instrument.equals(selected),
                  })}
                  onClick={() => {
                    setSelected(instrument)
                    onInstrumentChange?.(instrument)
                  }}
                >
                  {instrument.name}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export interface ShowInstrumentCollectionModalProps {
  initial: MFInstrument
  onInstrumentChange?: (instrument: MFInstrument) => void
}

export const showInstrumentCollectionModal = (
  props: ShowInstrumentCollectionModalProps,
) => {
  return new Promise<void>(resolve => {
    showModal({
      children: ({ dismiss }) => (
        <InstrumentCollectionModal onRequestClose={dismiss} {...props} />
      ),
      onRequestClose: ({ dismiss }) => dismiss(),
      onClose: () => resolve(),
      transition: 'fadeInOutLeft',
      style: {
        alignSelf: 'flex-start',
        width: 400,
        maxWidth: '100%',
        height: '100%',
      },
    })
  })
}

export default InstrumentCollectionModal
