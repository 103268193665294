import classNames from 'classnames'
import { TRACKS_EDITOR_ITEM_MARGIN_HORIZONTAL } from 'constants/tracks-editor-layout'
import {
  MFChord,
  MFNote,
  MFRef,
  MFTrackItem,
  MFTrackItemArray,
} from 'music-file'
import styles from './index.module.scss'

export interface TrackItemProps {
  unitWidth: number
  item: MFTrackItem
  pos: number
  selected?: boolean
}

const TrackItem = ({ unitWidth, item, pos, selected }: TrackItemProps) => {
  if (MFRef.is(item.source) || MFTrackItemArray.is(item.source)) {
    return null
  }

  const { source, begin, duration } = item
  const { name, octave } = source

  return (
    <div
      className={classNames(styles.trackItem, {
        [styles.note]: item.sourceType === MFNote,
        [styles.chord]: item.sourceType === MFChord,
        [styles.selected]: selected,
      })}
      style={{
        width: unitWidth * duration - TRACKS_EDITOR_ITEM_MARGIN_HORIZONTAL * 2,
        transform: `translateX(${unitWidth * begin}px) translate3d(0, 0, 0)`,
        marginLeft: TRACKS_EDITOR_ITEM_MARGIN_HORIZONTAL,
        marginRight: TRACKS_EDITOR_ITEM_MARGIN_HORIZONTAL,
      }}
      data-type="trackItem"
      data-pos={pos}
    >
      <div className={styles.name}>{name}</div>
      <div className={styles.octave}>{octave}</div>
    </div>
  )
}

export default TrackItem
