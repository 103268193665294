import { EffectCallback, useLayoutEffect } from 'react'

const useFirstFrameEffect = (cb: EffectCallback) => {
  useLayoutEffect(() => {
    requestAnimationFrame(cb)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useFirstFrameEffect
