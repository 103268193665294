import { showDialog } from 'components/dialog'
import { handleFullscreenLoading } from 'components/fullscreen-loading'
import {
  DEFAULT_INSTRUMENT,
  DEFAULT_INSTRUMENT_URL,
} from 'constants/instrument-collection'
import useConst from 'hooks/use-const'
import useOnce from 'hooks/use-once'
import { MFChord, MFNote, MFTrack, MFTrackItem } from 'music-file'
import { MFMusicFilePlayer } from 'music-file-player'
import {
  MFSampleLoader,
  MFSampleManager,
  MFSamplePlayer,
} from 'music-file-sampler'
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { useMusicFileContext } from './music-file'

export type PlaybackStatus = 'stopped' | 'paused' | 'playing'

export interface MusicFilePlaybackContext {
  sampleManager: MFSampleManager
  sampleLoader: MFSampleLoader
  samplePlayer: MFSamplePlayer
  currentStatus: PlaybackStatus
  currentTick: number
  setCurrentStatus: SetStateDispatch<PlaybackStatus>
  setCurrentTick: SetStateDispatch<number>
  setCurrentTickSafe: (tick: number) => void
  stop: () => void
  pause: () => void
  play: () => void
  playTrackItem: (track: number | MFTrack, trackItem: MFTrackItem) => void
}

const context = createContext({} as MusicFilePlaybackContext)

const { Provider } = context

export const useMusicFilePlaybackContext = () => useContext(context)

export const MusicFilePlaybackProvider = ({ children }: PropsWithChildren) => {
  const { musicFile } = useMusicFileContext()

  const audioContext = useConst(() => new AudioContext())
  const sampleManager = useConst(() => new MFSampleManager())
  const sampleLoader = useConst(
    () => new MFSampleLoader(audioContext, sampleManager),
  )
  const samplePlayer = useConst(
    () => new MFSamplePlayer(audioContext, sampleManager),
  )
  const musicFilePlayer = useConst(() => new MFMusicFilePlayer(samplePlayer))
  const musicFileCacheRef = useRef(musicFile)

  const [currentStatus, setCurrentStatus] = useState<PlaybackStatus>('stopped')
  const [currentTick, setCurrentTick] = useState(0)

  const stop = useCallback(() => {
    musicFilePlayer.stop()
    musicFilePlayer.setCurrentTick(0)

    setCurrentStatus('stopped')
    setCurrentTick(0)
  }, [musicFilePlayer])

  const pause = useCallback(() => {
    musicFilePlayer.stop()

    setCurrentStatus('paused')
  }, [musicFilePlayer])

  const play = useCallback(() => {
    musicFilePlayer.setMusicFile(musicFile)
    musicFilePlayer.setCurrentTick(currentTick)
    musicFilePlayer.start()

    setCurrentStatus('playing')
  }, [currentTick, musicFile, musicFilePlayer])

  const playTrackItem = (track: number | MFTrack, trackItem: MFTrackItem) => {
    const { source } = trackItem
    const { instrument, volume } =
      typeof track === 'number' ? musicFile.tracks.at(track) : track

    if (MFNote.is(source)) {
      samplePlayer.playSampleByNote({
        instrumentURI: instrument.resourceURI,
        note: source,
        key: musicFile.key,
        volume,
        durationMs: 1000,
      })
    } else if (MFChord.is(source)) {
      samplePlayer.playSamplesByChord({
        instrumentURI: instrument.resourceURI,
        chord: source,
        key: musicFile.key,
        volume,
        durationMs: 1000,
      })
    }
  }

  const setCurrentTickSafe = useCallback(
    (tick: number) => {
      if (currentStatus === 'playing') {
        musicFilePlayer.setCurrentTick(tick)
        musicFilePlayer.setMusicFile(musicFile)

        setCurrentTick(tick)
      } else {
        musicFilePlayer.setCurrentTick(tick)

        setCurrentTick(tick)
      }
    },
    [currentStatus, musicFile, musicFilePlayer],
  )

  const value: MusicFilePlaybackContext = {
    sampleManager,
    sampleLoader,
    samplePlayer,
    currentStatus,
    currentTick,
    setCurrentStatus,
    setCurrentTick,
    setCurrentTickSafe,
    stop,
    pause,
    play,
    playTrackItem,
  }

  useLayoutEffect(() => {
    if (musicFileCacheRef.current !== musicFile) {
      musicFileCacheRef.current = musicFile

      if (currentStatus === 'playing') {
        musicFilePlayer.setMusicFile(musicFile)
      }
    }
  }, [musicFile, currentStatus, musicFilePlayer])

  useLayoutEffect(() => {
    if (currentTick >= musicFile.numTicks) {
      pause()
      setCurrentTick(musicFile.numTicks - 1)
    }
  }, [currentTick, musicFile, pause])

  useLayoutEffect(() => {
    musicFilePlayer.subscribe((tick, end) => {
      setCurrentTick(tick)

      if (end) {
        setCurrentStatus('stopped')
      }
    })
  }, [musicFilePlayer])

  useOnce(() => {
    musicFilePlayer.setMusicFile(musicFile)

    handleFullscreenLoading(() =>
      sampleLoader.loadInstrumentFromURL(
        DEFAULT_INSTRUMENT.resourceURI,
        DEFAULT_INSTRUMENT_URL,
      ),
    ).then(() => {
      showDialog({
        title: 'Enable Browser Audio',
        description:
          'Web Audio API will keep suspended until any user interaction has been triggered. ' +
          'Please click the "Activate" button to request permission and enable browser audio.',
        buttons: [
          {
            value: true,
            title: 'Activate',
            onClick: () => audioContext.resume(),
          },
        ],
      })
    })
  })

  return <Provider value={value}>{children}</Provider>
}
