import {
  MFChord,
  MFChordName,
  MFMusicFile,
  MFNote,
  MFNoteName,
  MFTrack,
  MFTrackItem,
} from 'music-file'

type NoteToChordProbMap = {
  [note in MFNoteName]?: {
    [chord in MFChordName]?: number
  }
}

const NOTE_TO_CHORD_PROB_MAP: NoteToChordProbMap = {
  do: { I: 0.5, vi: 0.25, IV: 0.25 },
  re: { ii: 0.4, V: 0.35, 'V/V': 0.2, 'vii-dim': 0.05 },
  mi: { I: 0.5, vi: 0.3, iii: 0.2 },
  fa: { IV: 0.6, ii: 0.35, 'vii-dim': 0.05 },
  'fa#': { 'V/V': 1.0 },
  sol: { I: 0.4, V: 0.5, iii: 0.1 },
  la: { vi: 0.5, IV: 0.3, ii: 0.1, 'V/V': 0.1 },
  ti: { V: 0.65, iii: 0.2, 'vii-dim': 0.15 },
}

export default class ChordAnalysis {
  private melodicItems: MFTrackItem[]
  private numTicksPerBar: number

  constructor(private readonly musicFile: MFMusicFile) {
    this.melodicItems = []
    this.numTicksPerBar = musicFile.numTicksPerBar

    for (const track of musicFile.tracks.toArray()) {
      const items = track.items
        .toArray()
        .filter(item => item.sourceType === MFNote)

      this.melodicItems.push(...items)
    }
  }

  getPredictedChordsByTrackItems(trackItems: MFTrackItem[]) {
    if (trackItems.length === 0) {
      return []
    }

    const items = trackItems.filter(item => item.sourceType === MFNote)
    const matched: Partial<Record<MFChordName, number>> = {}

    const head = items[0]
    const tail = items[items.length - 1]

    if (!MFNote.is(head.source) || !MFNote.is(tail.source)) {
      return []
    }

    const headChordProbs = NOTE_TO_CHORD_PROB_MAP[head.source.name]
    const tailChordProbs = NOTE_TO_CHORD_PROB_MAP[tail.source.name]

    if (headChordProbs) {
      for (const chordName of Object.keys(headChordProbs) as MFChordName[]) {
        matched[chordName] ??= 0
        matched[chordName] =
          matched[chordName]! +
          headChordProbs[chordName]! *
            (head.duration / this.numTicksPerBar) *
            1.05
      }
    }

    if (tailChordProbs) {
      for (const chordName of Object.keys(tailChordProbs) as MFChordName[]) {
        matched[chordName] ??= 0
        matched[chordName] =
          matched[chordName]! +
          tailChordProbs[chordName]! *
            (head.duration / this.numTicksPerBar) *
            0.95
      }
    }

    const matchedEntries = Object.entries(matched) as [MFChordName, number][]
    const results = matchedEntries
      .sort(([, a], [, b]) => (a > b ? -1 : 1))
      .map(([chordName, prob]) => [new MFChord(chordName, 3), prob] as const)

    return results
  }

  getPredictedChordsInDuration(begin: number, end: number) {
    return this.getPredictedChordsByTrackItems(
      this.melodicItems.filter(item => item.begin >= begin && item.end <= end),
    )
  }

  getPredictedChordsInBar(barIndex: number) {
    const begin = barIndex * this.numTicksPerBar
    const end = (barIndex + 1) * this.numTicksPerBar

    return this.getPredictedChordsByTrackItems(
      this.melodicItems.filter(item => item.begin >= begin && item.end <= end),
    )
  }

  getPredictedChordProgression(baseTrack?: MFTrack) {
    const items = baseTrack ? baseTrack.items.toArray() : []
    const results: MFTrackItem[] = []

    for (let i = 0; i < this.musicFile.numBars; i++) {
      const matchResults = this.getPredictedChordsInBar(i)

      if (matchResults.length > 0) {
        const bestMatch = matchResults[0][0]
        const begin = i * this.numTicksPerBar
        const end = (i + 1) * this.numTicksPerBar
        const existed = items.filter(item => item.isTimeWithin(begin, end))

        if (existed.length === 0) {
          results.push(
            new MFTrackItem({
              source: bestMatch,
              begin,
              duration: this.numTicksPerBar,
            }),
          )
        } else {
          for (const item of existed) {
            if (item.begin >= begin) {
              results.push(item)
            }
          }
        }
      }
    }

    return results
  }
}
