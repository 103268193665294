import { ContextMenuItemProps, showContextMenu } from 'components/context-menu'
import { useState } from 'react'
import ToolbarButton, { ToolbarButtonProps } from '../toolbar-button'

export interface ToolbarMenuProps<T>
  extends Omit<ToolbarButtonProps, 'selected' | 'onClick'> {
  items: ContextMenuItemProps<T>[]
  onResult?: (value: T) => void
}

const ToolbarMenu = <T,>({
  items,
  onResult,
  ...props
}: ToolbarMenuProps<T>) => {
  const [selected, setSelected] = useState(false)

  return (
    <ToolbarButton
      selected={selected}
      onClick={async event => {
        setSelected(true)

        const rect = event.currentTarget.getBoundingClientRect()
        const x = rect.left
        const y = rect.top + rect.height + 8

        const result = await showContextMenu({
          position: { x, y },
          items,
        })

        if (result !== null) {
          onResult?.(result)
        }

        setSelected(false)
      }}
      {...props}
    />
  )
}

export default ToolbarMenu
