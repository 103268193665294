import { MFInstrument } from 'music-file'

export const DEFAULT_INSTRUMENT = new MFInstrument({
  name: 'Acoustic Grand Piano',
  resourceURI: 'instrument:studio:acoustic_grand_piano',
})

export const DEFAULT_INSTRUMENT_URL =
  'https://sounds-cdn.improvising.io/acoustic_grand_piano'

export const INSTRUMENT_COLLECTION: readonly {
  name: string
  instruments: MFInstrument[]
}[] = [
  {
    name: 'Piano',
    instruments: [
      DEFAULT_INSTRUMENT,
      new MFInstrument({
        name: 'Bright Acoustic Piano',
        resourceURI: 'instrument:studio:bright_acoustic_piano',
      }),
    ],
  },
  {
    name: 'Electric Piano',
    instruments: [
      new MFInstrument({
        name: 'Electric Grand Piano 1',
        resourceURI: 'instrument:studio:electric_grand_piano',
      }),
      new MFInstrument({
        name: 'Electric Piano 1',
        resourceURI: 'instrument:studio:electric_piano_1',
      }),
      new MFInstrument({
        name: 'Electric Piano 2',
        resourceURI: 'instrument:studio:electric_piano_2',
      }),
      new MFInstrument({
        name: 'Honkytonk Piano',
        resourceURI: 'instrument:studio:honkytonk_piano',
      }),
    ],
  },
  {
    name: 'Guitar',
    instruments: [
      new MFInstrument({
        name: 'Acoustic Guitar Steel',
        resourceURI: 'instrument:studio:acoustic_guitar_steel',
      }),
      new MFInstrument({
        name: 'Acoustic Guitar Nylon',
        resourceURI: 'instrument:studio:acoustic_guitar_nylon',
      }),
      new MFInstrument({
        name: 'Distortion Guitar',
        resourceURI: 'instrument:studio:distortion_guitar',
      }),
      new MFInstrument({
        name: 'Electric Guitar Clean',
        resourceURI: 'instrument:studio:electric_guitar_clean',
      }),
      new MFInstrument({
        name: 'Electric Guitar Jazz',
        resourceURI: 'instrument:studio:electric_guitar_jazz',
      }),
      new MFInstrument({
        name: 'Electric Guitar Muted',
        resourceURI: 'instrument:studio:electric_guitar_muted',
      }),
      new MFInstrument({
        name: 'Guitar Fret Noise',
        resourceURI: 'instrument:studio:guitar_fret_noise',
      }),
      new MFInstrument({
        name: 'Guitar Harmonics',
        resourceURI: 'instrument:studio:guitar_harmonics',
      }),
    ],
  },
  {
    name: 'Organ',
    instruments: [
      new MFInstrument({
        name: 'Church Organ',
        resourceURI: 'instrument:studio:church_organ',
      }),
      new MFInstrument({
        name: 'Drawbar Organ',
        resourceURI: 'instrument:studio:drawbar_organ',
      }),
      new MFInstrument({
        name: 'Percussive Organ',
        resourceURI: 'instrument:studio:percussive_organ',
      }),
      new MFInstrument({
        name: 'Reed Organ',
        resourceURI: 'instrument:studio:reed_organ',
      }),
      new MFInstrument({
        name: 'Rock Organ',
        resourceURI: 'instrument:studio:rock_organ',
      }),
    ],
  },
  {
    name: 'Orchestra',
    instruments: [
      new MFInstrument({
        name: 'Violin',
        resourceURI: 'instrument:studio:violin',
      }),
      new MFInstrument({
        name: 'Viola',
        resourceURI: 'instrument:studio:viola',
      }),
      new MFInstrument({
        name: 'Cello',
        resourceURI: 'instrument:studio:cello',
      }),
      new MFInstrument({
        name: 'Pizzicato Strings',
        resourceURI: 'instrument:studio:pizzicato_strings',
      }),
      new MFInstrument({
        name: 'String Ensemble 1',
        resourceURI: 'instrument:studio:string_ensemble_1',
      }),
      new MFInstrument({
        name: 'String Ensemble 2',
        resourceURI: 'instrument:studio:string_ensemble_2',
      }),
    ],
  },
]
