import { PortalHost } from 'components/portal'
import Scrollbar, { ScrollbarRef } from 'components/scrollbar'
import { MusicFileProvider, useMusicFileContext } from 'providers/music-file'
import { MusicFilePlaybackProvider } from 'providers/music-file-playback'
import { useLayoutEffect, useMemo, useRef } from 'react'
import PlaybackToolbar from './components/playback-toolbar'
import StatusBar from './components/status-bar'
import TracksEditor from './components/tracks-editor'
import TracksEditorToolbar from './components/tracks-editor-toolbar'
import styles from './index.module.scss'
import { TracksEditorProvider } from './providers/tracks-editor'

const Page = () => {
  const { musicFile } = useMusicFileContext()

  const scrollbarRef = useRef<ScrollbarRef>(null)
  const tracksEditorRef = useRef<HTMLDivElement>(null)

  const musicFileCacheRef = useRef(musicFile)

  useLayoutEffect(() => {
    const lastNumBars = musicFileCacheRef.current.numBars
    const diff = musicFile.numBars - lastNumBars

    musicFileCacheRef.current = musicFile

    if (diff === 0) {
      return
    }

    requestAnimationFrame(() => {
      if (diff > 0) {
        const tracksEditor = tracksEditorRef.current

        if (tracksEditor) {
          tracksEditor.scrollTo({
            left: tracksEditor.scrollWidth,
            behavior: 'smooth',
          })
        }
      } else {
        scrollbarRef.current?.updateScrollBar()
      }
    })
  }, [musicFile])

  return useMemo(() => {
    return (
      <div
        className={styles.page}
        onContextMenu={event => event.preventDefault()}
      >
        <PlaybackToolbar />
        <TracksEditorToolbar />
        <TracksEditor ref={tracksEditorRef} className={styles.tracksEditor} />
        <Scrollbar
          ref={scrollbarRef}
          className={styles.scrollbar}
          scrollableRef={tracksEditorRef}
          axis="horizontal"
        />
        <StatusBar />
      </div>
    )
  }, [])
}

export default {
  path: '/workbench',
  element: (
    <MusicFileProvider>
      <MusicFilePlaybackProvider>
        <TracksEditorProvider>
          <Page />
          <PortalHost />
        </TracksEditorProvider>
      </MusicFilePlaybackProvider>
    </MusicFileProvider>
  ),
} as const
