import classNames from 'classnames'
import { showModal } from 'components/modal'
import styles from './index.module.scss'

export interface FullscreenLoadingProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'children'> {
  message?: string
}

const FullscreenLoading = ({
  message,
  className,
  ...props
}: FullscreenLoadingProps) => {
  return (
    <div className={classNames(styles.fullscreenLoading, className)} {...props}>
      <div className={styles.spinner}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
      {message && <div className={styles.message}>{message}</div>}
    </div>
  )
}

export const showFullscreenLoading = ({
  message,
}: FullscreenLoadingProps = {}) => {
  return showModal({
    children: () => <FullscreenLoading message={message} />,
  })
}

export const handleFullscreenLoading = <T,>(
  promiseCb: () => Promise<T>,
  props: FullscreenLoadingProps = {},
) =>
  new Promise<T>((resolve, reject) => {
    const dispose = showFullscreenLoading(props)

    promiseCb()
      .then(result => {
        dispose()
        resolve(result)
      })
      .catch(reject)
  })

export default FullscreenLoading
