import { MFChord, MFNote, MFRef } from 'music-file';
export class MFMusicFilePlayer {
    constructor(samplePlayer) {
        this.samplePlayer = samplePlayer;
        this.currentTick = 0;
        this.playing = false;
        this.state = null;
        this.subscribers = [];
    }
    setMusicFile(musicFile) {
        this.state = this.compileState(musicFile);
    }
    setCurrentTick(tick) {
        if (this.state === null) {
            throw new Error('state is not compiled');
        }
        this.currentTick = tick;
        return this;
    }
    getCurrentTick() {
        return this.currentTick;
    }
    isPlaying() {
        return this.playing;
    }
    start() {
        if (this.state === null) {
            throw new Error('state is not compiled');
        }
        this.playing = true;
        this.tick();
        return this;
    }
    stop() {
        if (this.state === null) {
            throw new Error('state is not compiled');
        }
        this.playing = false;
        return this;
    }
    subscribe(cb) {
        this.subscribers.push(cb);
        return () => {
            this.subscribers = this.subscribers.filter(value => value !== cb);
        };
    }
    tick() {
        if (!this.playing || !this.state) {
            return;
        }
        if (this.currentTick >= this.state.musicFile.numTicks) {
            this.playing = false;
            this.notifySubscribers();
            return;
        }
        if (this.currentTick in this.state.timeline) {
            for (const [trackNum, item] of this.state.timeline[this.currentTick]) {
                this.playTrackItem(trackNum, item);
            }
        }
        this.notifySubscribers();
        this.currentTick++;
        setTimeout(this.tick.bind(this), this.state.musicFile.tickMs);
    }
    playTrackItem(trackNum, trackItem) {
        if (!this.playing || !this.state) {
            return;
        }
        const { key, tickMs, tracks } = this.state.musicFile;
        const { instrument, volume } = tracks.at(trackNum);
        const { source, duration } = trackItem;
        if (MFNote.is(source)) {
            this.samplePlayer.playSampleByNote({
                instrumentURI: instrument.resourceURI,
                note: source,
                key,
                volume,
                durationMs: duration * tickMs,
            });
        }
        else if (MFChord.is(source)) {
            this.samplePlayer.playSamplesByChord({
                instrumentURI: instrument.resourceURI,
                chord: source,
                key,
                volume,
                durationMs: duration * tickMs,
            });
        }
        else if (MFRef.is(source)) {
            if (source.typeURI === 'type:sampler:sample') {
                this.samplePlayer.playSample({
                    instrumentURI: instrument.resourceURI,
                    sampleURI: source.resourceURI,
                    volume,
                    durationMs: duration * tickMs,
                });
            }
        }
    }
    compileState(musicFile) {
        var _a;
        var _b, _c;
        const { tracks } = musicFile;
        const state = {
            musicFile,
            timeline: {},
        };
        for (let trackNum = 0; trackNum < musicFile.tracks.length; trackNum++) {
            const { items } = tracks.at(trackNum);
            for (const item of items.toArray()) {
                (_a = (_b = state.timeline)[_c = item.begin]) !== null && _a !== void 0 ? _a : (_b[_c] = []);
                state.timeline[item.begin].push([trackNum, item]);
            }
        }
        return state;
    }
    notifySubscribers() {
        if (!this.state) {
            return;
        }
        const currentTick = this.currentTick;
        const end = currentTick === this.state.musicFile.numTicks;
        this.subscribers.forEach(cb => cb(currentTick, end));
    }
}
