export default function assert(
  value: unknown,
  message?: string | Error,
): asserts value {
  if (!Boolean(value)) {
    if (message instanceof Error) {
      throw message
    } else {
      throw new Error(message)
    }
  }
}
