import Dialog from 'components/dialog'
import { showModal } from 'components/modal'
import {
  ensureValidKeyName,
  ensureValidSignatureBeatNoteType,
  ensureValidSignatureNumBeats,
  MFKey,
  MFSignature,
  MFSignatureTuple,
  MUSIC_FILE_KEY_NAMES,
  MUSIC_FILE_SIGNATURE_TUPLES,
} from 'music-file'
import { useMusicFileContext } from 'providers/music-file'
import { useState } from 'react'
import styles from './index.module.scss'

const toDisplaySignature = (tuple: MFSignatureTuple) => {
  return `${tuple[0]}/${tuple[1]}`
}

const toSignature = (str: string) => {
  const [numBeats, beatNoteType] = str.split('/')

  return new MFSignature(
    ensureValidSignatureNumBeats(Number(numBeats)),
    ensureValidSignatureBeatNoteType(Number(beatNoteType)),
  )
}

export interface MusicFileSettingsDialogProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'children'> {
  onRequestClose?: (saved: boolean) => void
}

const MusicFileSettingsDialog = ({
  onRequestClose,
  ...props
}: MusicFileSettingsDialogProps) => {
  const { musicFile, updateMusicFile } = useMusicFileContext()

  const [name, setName] = useState(musicFile.name)
  const [key, setKey] = useState(musicFile.key)
  const [signature, setSignature] = useState(musicFile.signature)
  const [bpm, setBPM] = useState(musicFile.bpm)

  return (
    <Dialog
      title="MusicFile Settings"
      content={
        <div className={styles.items}>
          <div className={styles.item}>
            <div className={styles.label}>Name</div>
            <input
              value={name}
              onChange={event => setName(event.target.value)}
            />
          </div>
          <div className={styles.item}>
            <div className={styles.label}>Key</div>
            <select
              value={key.name}
              onChange={event =>
                setKey(new MFKey(ensureValidKeyName(event.target.value)))
              }
            >
              {MUSIC_FILE_KEY_NAMES.map(item => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.item}>
            <div className={styles.label}>Signature</div>
            <select
              value={toDisplaySignature(signature.tuple)}
              onChange={event => setSignature(toSignature(event.target.value))}
            >
              {MUSIC_FILE_SIGNATURE_TUPLES.map(item => {
                const displaySignature = toDisplaySignature(item)

                return (
                  <option key={displaySignature} value={displaySignature}>
                    {displaySignature}
                  </option>
                )
              })}
            </select>
          </div>
          <div className={styles.item}>
            <div className={styles.label}>BPM</div>
            <input
              placeholder="BPM"
              value={bpm}
              onChange={event => setBPM(Number(event.target.value))}
            />
          </div>
        </div>
      }
      buttons={[
        { value: 'cancel' as const, title: 'Cancel', variant: 'secondary' },
        { value: 'save' as const, title: 'Save', variant: 'primary' },
      ]}
      onRequestClose={result => {
        if (result === 'save') {
          updateMusicFile(actions => {
            actions.musicFile.update({
              name,
              key,
              signature,
              bpm,
            })

            actions.musicFile.ensureMinValidNumBars()
          })

          onRequestClose?.(true)
        } else {
          onRequestClose?.(false)
        }
      }}
      {...props}
    />
  )
}

export const showMusicFileSettingsDialog = () => {
  return new Promise<boolean>(resolve => {
    showModal<boolean>({
      children: ({ dismiss }) => (
        <MusicFileSettingsDialog onRequestClose={dismiss} />
      ),
      onClose: result => resolve(Boolean(result)),
      transition: 'fadeInOutBottom',
    })
  })
}

export default MusicFileSettingsDialog
