import { FullGestureState, useGesture } from '@use-gesture/react'
import classNames from 'classnames'
import assert from 'helpers/assert'
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
} from 'react'
import styles from './index.module.scss'

export interface ScrollbarRef {
  updateScrollBar: () => void
}

export interface ScrollbarProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'children'> {
  scrollableRef: React.RefObject<HTMLDivElement>
  axis: 'vertical' | 'horizontal'
}

const calculate = ({
  trackSpace,
  scrollViewSpace,
  scrollExtent,
  scrollOffset,
}: {
  trackSpace: number
  scrollViewSpace: number
  scrollExtent: number
  scrollOffset: number
}) => {
  const contentRatio = scrollViewSpace / scrollExtent
  const trackRatio = trackSpace / scrollExtent

  const thumbSpace = contentRatio * trackSpace
  const thumbOffset = trackRatio * scrollOffset

  return { thumbSpace, thumbOffset }
}

const Scrollbar = forwardRef<ScrollbarRef, ScrollbarProps>(
  ({ scrollableRef, axis, className, ...props }, ref) => {
    const scrollbarRef = useRef<HTMLDivElement>(null)
    const thumbRef = useRef<HTMLDivElement>(null)

    const getTrackSpace = useCallback(() => {
      assert(scrollbarRef.current)

      const computedStyles = getComputedStyle(scrollbarRef.current)
      const paddingLeft = parseFloat(computedStyles.paddingLeft)
      const paddingRight = parseFloat(computedStyles.paddingRight)
      const paddingTop = parseFloat(computedStyles.paddingTop)
      const paddingBottom = parseFloat(computedStyles.paddingBottom)

      return axis === 'horizontal'
        ? scrollbarRef.current.offsetWidth - paddingLeft - paddingRight
        : scrollbarRef.current.offsetHeight - paddingTop - paddingBottom
    }, [axis])

    const getGestureDelta = (state: FullGestureState<'drag'>) => {
      return axis === 'horizontal' ? state.delta[0] : state.delta[1]
    }

    const getScrollExtent = useCallback(() => {
      assert(scrollableRef.current)

      return axis === 'horizontal'
        ? scrollableRef.current.scrollWidth
        : scrollableRef.current.scrollHeight
    }, [axis, scrollableRef])

    const getScrollOffset = useCallback(() => {
      assert(scrollableRef.current)

      return axis === 'horizontal'
        ? scrollableRef.current.scrollLeft
        : scrollableRef.current.scrollTop
    }, [axis, scrollableRef])

    const getScrollViewSpace = useCallback(() => {
      assert(scrollableRef.current)

      return axis === 'horizontal'
        ? scrollableRef.current.offsetWidth
        : scrollableRef.current.offsetHeight
    }, [axis, scrollableRef])

    const updateScrollBar = useCallback(() => {
      assert(scrollableRef.current)
      assert(thumbRef.current)
      assert(scrollbarRef.current)

      const trackSpace = getTrackSpace()
      const scrollViewSpace = getScrollViewSpace()
      const scrollExtent = getScrollExtent()
      const scrollOffset = getScrollOffset()

      const { thumbSpace, thumbOffset } = calculate({
        trackSpace,
        scrollViewSpace,
        scrollExtent,
        scrollOffset,
      })

      if (axis === 'horizontal') {
        thumbRef.current.style.width = `${thumbSpace}px`
        thumbRef.current.style.transform = `translateX(${thumbOffset}px) translate3d(0, 0, 0)`
      } else {
        thumbRef.current.style.height = `${thumbSpace}px`
        thumbRef.current.style.transform = `translateY(${thumbOffset}px) translate3d(0, 0, 0)`
      }
    }, [
      axis,
      getScrollExtent,
      getScrollOffset,
      getScrollViewSpace,
      getTrackSpace,
      scrollableRef,
    ])

    const updateScrollOffset = (offset: number) => {
      assert(scrollableRef.current)
      assert(scrollbarRef.current)

      const trackSpace = getTrackSpace()
      const scrollViewSpace = getScrollViewSpace()
      const scrollExtent = getScrollExtent()
      const scrollOffset = getScrollOffset()

      const { thumbSpace, thumbOffset } = calculate({
        trackSpace,
        scrollViewSpace,
        scrollExtent,
        scrollOffset,
      })

      if (
        thumbOffset >= 0 &&
        Math.round(trackSpace - thumbSpace - thumbOffset) >= 0
      ) {
        if (axis === 'horizontal') {
          scrollableRef.current.scrollLeft = offset
        } else {
          scrollableRef.current.scrollTop = offset
        }
      }
    }

    const bind = useGesture({
      onDrag: state => {
        const delta = getGestureDelta(state)
        const ratio = getTrackSpace() / getScrollExtent()

        updateScrollOffset(getScrollOffset() + delta / ratio)
      },
    })

    useLayoutEffect(() => {
      const scrollable = scrollableRef.current

      if (scrollable) {
        updateScrollBar()

        scrollable.addEventListener('scroll', updateScrollBar)

        return () => {
          scrollable.removeEventListener('scroll', updateScrollBar)
        }
      }
    }, [scrollableRef, axis, updateScrollBar])

    useImperativeHandle(ref, () => {
      return { updateScrollBar }
    })

    return (
      <div
        ref={scrollbarRef}
        className={classNames(
          styles.scrollbar,
          {
            [styles.horizontal]: axis === 'horizontal',
            [styles.vertical]: axis === 'vertical',
          },
          className,
        )}
        {...props}
      >
        <div ref={thumbRef} className={styles.thumb} {...bind()} />
      </div>
    )
  },
)

export default Scrollbar
