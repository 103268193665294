import classNames from 'classnames'
import styles from './index.module.scss'
import {
  InsertionTypeRenderer,
  MenuRenderer,
  SelectionRenderer,
} from './renderers'

export interface TracksEditorToolbarProps
  extends React.HTMLAttributes<HTMLDivElement> {}

const TracksEditorToolbar = ({
  className,
  ...props
}: TracksEditorToolbarProps) => {
  return (
    <div
      className={classNames(styles.tracksEditorToolbar, className)}
      {...props}
    >
      <MenuRenderer />
      <InsertionTypeRenderer />
      <SelectionRenderer />
    </div>
  )
}

export default TracksEditorToolbar
