import classNames from 'classnames'
import { TRACKS_EDITOR_HEADER_WIDTH } from 'constants/tracks-editor-layout'
import { forwardRef } from 'react'
import styles from './index.module.scss'
import TrackCanvas from './track-canvas'
import TrackHeaders from './track-headers'
import TrackTimeline from './track-timeline'

export interface TracksEditorProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'children'> {}

const TracksEditor = forwardRef<HTMLDivElement, TracksEditorProps>(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={classNames(styles.tracksEditor, className)}
        {...props}
      >
        <TrackTimeline
          className={styles.timeline}
          offset={TRACKS_EDITOR_HEADER_WIDTH}
        />
        <div className={styles.horizontalBox}>
          <TrackHeaders className={styles.trackHeaders} />
          <TrackCanvas className={styles.trackGrids} />
        </div>
      </div>
    )
  },
)

export default TracksEditor
