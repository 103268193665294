import { useRef } from 'react'
import useConst from './use-const'

const useObjectKeyStore = () => {
  const keys = useConst(() => new WeakMap<object, string>())
  const generated = useRef(0)

  const getObjectKey = (object: object) => {
    let key = keys.get(object)

    if (key === undefined) {
      key = String(generated.current++)
      keys.set(object, key)
    }

    return key
  }

  const replaceObjectKey = (source: object, target: object) => {
    const sourceValue = keys.get(source)

    if (sourceValue !== undefined) {
      keys.set(target, sourceValue)
      keys.delete(source)
    }
  }

  return { getObjectKey, replaceObjectKey }
}

export default useObjectKeyStore
