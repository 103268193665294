import { Fragment, isValidElement } from 'react'

export const mergeReactNodes = (nodes: React.ReactNode[]) => {
  return nodes
    .filter(isValidElement)
    .reduce<Nullable<JSX.Element>>((prev, curr) => {
      if (prev === null) {
        return curr
      }

      return (
        <Fragment>
          {prev}
          {curr}
        </Fragment>
      )
    }, null)
}
