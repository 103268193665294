import classNames from 'classnames'
import { ReactComponent as IconAdd } from 'icons/add.svg'
import { ReactComponent as IconPause } from 'icons/pause.svg'
import { ReactComponent as IconPlay } from 'icons/play.svg'
import { ReactComponent as IconRemove } from 'icons/remove.svg'
import { ReactComponent as IconSkipPrevious } from 'icons/skip-previous.svg'
import moment from 'moment'
import { useMusicFileContext } from 'providers/music-file'
import { useMusicFilePlaybackContext } from 'providers/music-file-playback'
import styles from './index.module.scss'

export interface PlaybackToolbarProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'children'> {}

const PlaybackToolbar = ({ className, ...props }: PlaybackToolbarProps) => {
  const { musicFile, updateMusicFile } = useMusicFileContext()
  const { currentStatus, currentTick, play, pause, stop } =
    useMusicFilePlaybackContext()

  const { numBars, numTicks, tickMs } = musicFile

  const totalDuration = Math.floor(numTicks * tickMs)
  const currentDuration = Math.floor(currentTick * tickMs)

  const totalTime = moment.utc(totalDuration).format('mm:ss')
  const currentTime = moment.utc(currentDuration).format('mm:ss')

  const increaseNumBars = () => {
    updateMusicFile(actions => {
      actions.musicFile.update({
        numBars: musicFile.numBars + 1,
      })
    })
  }

  const reduceNumBars = () => {
    if (
      musicFile.numBars > 1 &&
      musicFile.numBars > musicFile.minValidNumBars
    ) {
      updateMusicFile(actions => {
        actions.musicFile.update({
          numBars: musicFile.numBars - 1,
        })
      })
    }
  }

  return (
    <div className={classNames(styles.playbackToolbar, className)} {...props}>
      <div className={styles.button} onClick={stop}>
        <IconSkipPrevious />
      </div>
      {currentStatus === 'playing' ? (
        <div className={styles.button} onClick={pause}>
          <IconPause />
        </div>
      ) : (
        <div className={styles.button} onClick={play}>
          <IconPlay />
        </div>
      )}
      <div className={classNames(styles.time, styles.current)}>
        {currentTime}
      </div>
      <div className={classNames(styles.time, styles.total)}>{totalTime}</div>
      <div className={styles.bars}>
        {numBars.toString().padStart(3, '0')} BARS
      </div>
      <div className={styles.spacer} />
      <div className={styles.button} onClick={reduceNumBars}>
        <IconRemove />
      </div>
      <div className={styles.button} onClick={increaseNumBars}>
        <IconAdd />
      </div>
    </div>
  )
}

export default PlaybackToolbar
