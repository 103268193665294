import { ContextMenuItemProps } from 'components/context-menu'
import Sqaure from 'components/sqaure'
import { UNTITLED_MUSIC_FILE } from 'constants/music-file'
import { saveJsonFile } from 'helpers/file'
import { mergeReactNodes } from 'helpers/react-node'
import { ReactComponent as IconDelete } from 'icons/delete.svg'
import { ReactComponent as IconMenu } from 'icons/menu.svg'
import { ReactComponent as IconTrack } from 'icons/track.svg'
import {
  ensureValidOctave,
  MFChord,
  MFNote,
  MFTrack,
  MFTrackItem,
  MFTrackItemArray,
  MUSIC_FILE_NOTE_NAMES,
} from 'music-file'
import { useMusicFileContext } from 'providers/music-file'
import { useMusicFilePlaybackContext } from 'providers/music-file-playback'
import ChordAnalysis from 'services/chord-analysis'
import { useTracksEditorContext } from '../../providers/tracks-editor'
import { showImportMusicFileDialog } from '../import-music-file-dialog'
import { showMusicFileSettingsDialog } from '../music-file-settings-dialog'
import ToolbarButton from './toolbar-button'
import ToolbarMenu from './toolbar-menu'
import ToolbarRadioGroup from './toolbar-radio-group'

export const MenuRenderer = () => {
  const { musicFile, musicFileHistory, setMusicFile } = useMusicFileContext()

  return (
    <ToolbarMenu
      icon={<IconMenu />}
      items={[
        {
          type: 'item',
          value: 'musicFileSettings' as const,
          title: 'MusicFile Settings',
        },
        { type: 'divider' },
        {
          type: 'item',
          value: 'importMusicFile' as const,
          title: 'Import MusicFile',
        },
        {
          type: 'item',
          value: 'exportAsMusicFile' as const,
          title: 'Export as MusicFile',
        },
        { type: 'divider' },
        {
          type: 'item',
          value: 'undo' as const,
          title: 'Undo',
          disabled: !musicFileHistory.hasPrev,
        },
        {
          type: 'item',
          value: 'redo' as const,
          title: 'Redo',
          disabled: !musicFileHistory.hasNext,
        },
        { type: 'divider' },
        {
          type: 'item',
          value: 'closeMusicFile' as const,
          title: 'Close',
        },
      ]}
      onResult={result => {
        switch (result) {
          case 'musicFileSettings':
            return showMusicFileSettingsDialog()
          case 'importMusicFile':
            return showImportMusicFileDialog()
          case 'exportAsMusicFile': {
            const name = `${musicFile.name
              .replace(/\s/g, '_')
              .toLowerCase()}.musicfile.json`

            return saveJsonFile(name, musicFile)
          }
          case 'undo': {
            return musicFileHistory.undo()
          }
          case 'redo': {
            return musicFileHistory.redo()
          }
          case 'closeMusicFile': {
            return setMusicFile(UNTITLED_MUSIC_FILE)
          }
        }
      }}
    />
  )
}

export const InsertionTypeRenderer = () => {
  const { insertType, setInsertType } = useTracksEditorContext()

  return (
    <ToolbarRadioGroup
      items={[
        {
          value: 'note' as const,
          icon: <Sqaure size={16} borderRadius={4} backgroundColor="#1d8065" />,
          title: 'NOTE',
        },
        {
          value: 'chord' as const,
          icon: <Sqaure size={16} borderRadius={4} backgroundColor="#2476a6" />,
          title: 'CHORD',
        },
      ]}
      currentValue={insertType}
      onValueChange={setInsertType}
    />
  )
}

export const SelectionRenderer = () => {
  const { musicFile, updateMusicFile } = useMusicFileContext()
  const { playTrackItem } = useMusicFilePlaybackContext()

  const { selections, setSelections } = useTracksEditorContext()

  if (selections.length === 0) {
    return null
  }

  const selectedTracks = selections.filter(MFTrack.is)
  const selectedTrackItems = selections.filter(MFTrackItem.is)

  const nodes: React.ReactNode[] = []

  if (selectedTracks.length === 1) {
    const track = selectedTracks[0]
    const menuItems: ContextMenuItemProps<'generateChords'>[] = []

    if (!track.items.has(item => item.sourceType === MFNote)) {
      menuItems.push({
        type: 'item',
        value: 'generateChords' as const,
        title: 'Generate Chords',
      })
    }

    if (menuItems.length === 0) {
      return null
    }

    return (
      <ToolbarMenu
        icon={<IconTrack />}
        title="TRACK"
        items={menuItems}
        onResult={result => {
          switch (result) {
            case 'generateChords': {
              const chordAnalysis = new ChordAnalysis(musicFile)
              const items = chordAnalysis.getPredictedChordProgression(track)

              updateMusicFile(actions => {
                const updated = actions.musicFile.tracks
                  .select(track)
                  .update({ items: MFTrackItemArray.fromArray(items) })

                setSelections([updated])
              })
            }
          }
        }}
      />
    )
  }

  if (selectedTrackItems.length > 0) {
    nodes.push(
      <ToolbarButton
        icon={<IconDelete />}
        onClick={() => {
          updateMusicFile(actions => {
            selectedTrackItems.forEach(trackItem => {
              const [trackNum, pos] = actions.musicFile.get().locate(trackItem)

              actions.musicFile.tracks
                .select(trackNum)
                .items.select(pos)
                .delete()

              setSelections(selections =>
                selections.filter(value => value !== trackItem),
              )
            })
          })
        }}
      />,
    )

    nodes.push(
      <ToolbarButton
        title="+12"
        onClick={() => {
          const items = selectedTrackItems

          updateMusicFile(actions => {
            const updates = items.map(trackItem => {
              const [trackNum, pos] = actions.musicFile.get().locate(trackItem)

              const item = actions.musicFile.tracks
                .select(trackNum)
                .items.select(pos)
                .update(item => ({
                  source:
                    'octave' in item.source
                      ? item.source.copy({
                          octave: ensureValidOctave(item.source.octave + 1),
                        })
                      : item.source,
                }))

              return { item, trackNum, pos }
            })

            setSelections(selections =>
              selections
                .filter(value => !items.some(item => item === value))
                .concat(updates.map(update => update.item)),
            )

            if (updates.length === 1) {
              playTrackItem(updates[0].trackNum, updates[0].item)
            }
          })
        }}
      />,
    )

    nodes.push(
      <ToolbarButton
        title="-12"
        onClick={() => {
          const items = selectedTrackItems

          updateMusicFile(actions => {
            const updates = items.map(trackItem => {
              const [trackNum, pos] = actions.musicFile.get().locate(trackItem)

              const item = actions.musicFile.tracks
                .select(trackNum)
                .items.select(pos)
                .update(item => ({
                  source:
                    'octave' in item.source
                      ? item.source.copy({
                          octave: ensureValidOctave(item.source.octave - 1),
                        })
                      : item.source,
                }))

              return { item, trackNum, pos }
            })

            setSelections(selections =>
              selections
                .filter(value => !items.some(item => item === value))
                .concat(updates.map(update => update.item)),
            )

            if (updates.length === 1) {
              playTrackItem(updates[0].trackNum, updates[0].item)
            }
          })
        }}
      />,
    )
  }

  if (selectedTrackItems.length === 1) {
    const trackItem = selectedTrackItems[0]
    const source = trackItem.source

    if (MFNote.is(source)) {
      nodes.push(
        <ToolbarRadioGroup
          items={MUSIC_FILE_NOTE_NAMES.map(name => ({
            value: name,
            title: name,
          }))}
          currentValue={source.name}
          onValueChange={value => {
            const [trackNum, pos] = musicFile.locate(trackItem)

            updateMusicFile(actions => {
              const updatedTrackItem = actions.musicFile.tracks
                .select(trackNum)
                .items.select(pos)
                .update({
                  source: source.copy({ name: value }),
                })

              setSelections(selections =>
                selections
                  .filter(value => value !== trackItem)
                  .concat(updatedTrackItem),
              )

              playTrackItem(trackNum, updatedTrackItem)
            })
          }}
        />,
      )
    } else if (MFChord.is(source)) {
      const chordAnalysis = new ChordAnalysis(musicFile)
      const chords = chordAnalysis
        .getPredictedChordsInDuration(trackItem.begin, trackItem.end)
        .map(([chord]) => chord)

      nodes.push(
        <ToolbarRadioGroup
          items={chords.map(chord => ({
            value: chord.name,
            title: chord.name,
          }))}
          currentValue={source.name}
          onValueChange={value => {
            const [trackNum, pos] = musicFile.locate(trackItem)

            updateMusicFile(actions => {
              const updatedTrackItem = actions.musicFile.tracks
                .select(trackNum)
                .items.select(pos)
                .update({
                  source: source.copy({ name: value }),
                })

              setSelections(selections =>
                selections
                  .filter(value => value !== trackItem)
                  .concat(updatedTrackItem),
              )

              playTrackItem(trackNum, updatedTrackItem)
            })
          }}
        />,
      )
    }
  }

  return mergeReactNodes(nodes)
}
