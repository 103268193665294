import classNames from 'classnames'
import styles from './index.module.scss'

export interface ToolbarButtonProps
  extends Omit<React.HTMLAttributes<HTMLElement>, 'children'> {
  icon?: React.ReactNode
  title?: string
  selected?: boolean
}

const ToolbarButton = ({
  icon,
  title,
  selected = false,
  className,
  ...props
}: ToolbarButtonProps) => {
  return (
    <div
      className={classNames(
        styles.toolbarButton,
        {
          [styles.selected]: selected,
        },
        className,
      )}
      {...props}
    >
      {icon && <div className={styles.icon}>{icon}</div>}
      {title && <div className={styles.title}>{title}</div>}
    </div>
  )
}

export default ToolbarButton
