import classNames from 'classnames'
import Gridlines from 'components/gridlines'
import { range } from 'lodash'
import { useMusicFileContext } from 'providers/music-file'
import { useMusicFilePlaybackContext } from 'providers/music-file-playback'
import { useMemo } from 'react'
import { computeTracksEditorLayout } from '../../../helpers/tracks-editor'
import { useTracksEditorContext } from '../../../providers/tracks-editor'
import styles from './index.module.scss'

export interface TrackTimelineProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'children'> {
  offset?: number
}

const TrackTimeline = ({
  offset = 0,
  className,
  style,
  onClick,
  ...props
}: TrackTimelineProps) => {
  const { setSelections } = useTracksEditorContext()

  const { musicFile } = useMusicFileContext()
  const { currentStatus, currentTick, setCurrentTickSafe } =
    useMusicFilePlaybackContext()

  const {
    canvasWidth,
    barWidth,
    gridWidth,
    numBars,
    numGridsPerBar,
    numGridsPerBeat,
    numTicksPerGrid,
    tickMs,
  } = computeTracksEditorLayout(musicFile)

  const dx = Math.floor(currentTick / numTicksPerGrid) * gridWidth
  const gridMs = Math.ceil(tickMs * numTicksPerGrid)

  const gridlines = useMemo(
    () => (
      <Gridlines
        className={styles.gridlines}
        style={{ left: offset }}
        canvasWidth={canvasWidth}
        canvasHeight={30}
        pattern={{
          width: barWidth,
          height: '100%',
          lines: range(1, numGridsPerBar + 1).map(i => {
            const x = i * gridWidth
            const stroke =
              i % numGridsPerBar === 0
                ? '#ffffff28'
                : i % numGridsPerBeat === 0
                ? '#ffffff12'
                : '#ffffff06'

            return {
              x1: x,
              x2: x,
              y1: 18,
              y2: '100%',
              stroke,
              strokeWidth: 1,
            }
          }),
        }}
      />
    ),
    [barWidth, canvasWidth, gridWidth, numGridsPerBar, numGridsPerBeat, offset],
  )

  const labels = useMemo(() => {
    const nodes: React.ReactNode[] = []

    for (let i = 0; i < numBars; i++) {
      nodes.push(
        <div
          key={`text_${i}`}
          className={styles.label}
          style={{ left: i * barWidth }}
        >
          {i}
        </div>,
      )
    }

    return (
      <div className={styles.labels} style={{ left: offset }}>
        {nodes}
      </div>
    )
  }, [barWidth, numBars, offset])

  const handleClick: React.MouseEventHandler<HTMLDivElement> = event => {
    setSelections([])

    const rect = event.currentTarget.getBoundingClientRect()
    const dx = event.clientX - rect.left - offset
    const ticks = Math.floor(dx / gridWidth) * numTicksPerGrid

    if (ticks >= 0) {
      setCurrentTickSafe(ticks)
    }

    onClick?.(event)
  }

  return (
    <div
      className={classNames(styles.trackTimeline, className)}
      style={{ width: canvasWidth + offset, ...style }}
      onClick={handleClick}
      {...props}
    >
      {gridlines}
      {labels}
      <div
        className={styles.playhead}
        style={{
          transform: `translateX(${dx}px) translate3d(0, 0, 0)`,
          left: offset,
          width: gridWidth,
          ...(currentStatus === 'playing' && {
            transition: `transform ${gridMs}ms`,
            transitionTimingFunction: 'linear',
          }),
        }}
      />
    </div>
  )
}

export default TrackTimeline
