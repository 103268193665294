import { EffectCallback, useLayoutEffect, useRef } from 'react'

const useOnce = (cb: EffectCallback) => {
  const onceRef = useRef(false)

  return useLayoutEffect(() => {
    if (!onceRef.current) {
      onceRef.current = true

      return cb()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useOnce
