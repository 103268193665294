import classNames from 'classnames'
import { showModal } from 'components/modal'
import styles from './index.module.scss'

export interface ContextMenuProps<T>
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'children'> {
  items: ContextMenuItemProps<T>[]
  onRequestClose?: (value: T) => void
}

export type ContextMenuItemProps<T> =
  | {
      type: 'item'
      value: T
      title: string
      subtitle?: string
      disabled?: boolean
      onClick?: () => void
    }
  | { type: 'divider' }

const ContextMenu = <T,>({
  className,
  items,
  onRequestClose,
  ...props
}: ContextMenuProps<T>) => {
  return (
    <div className={classNames(styles.contextMenu, className)} {...props}>
      {items.map((item, index) => {
        if (item.type === 'divider') {
          return <div key={index} className={styles.divider} />
        }

        const { value, title, subtitle, disabled = false, onClick } = item

        return (
          <div
            key={index}
            className={classNames(styles.item, {
              [styles.disabled]: disabled,
            })}
            onClick={() => {
              onClick?.()
              onRequestClose?.(value)
            }}
          >
            <div className={styles.title}>{title}</div>
            {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
          </div>
        )
      })}
    </div>
  )
}

export interface ShowContextMenuProps<T>
  extends Omit<ContextMenuProps<T>, 'onRequestClose'> {
  position: { x: number; y: number }
}

export const showContextMenu = <T,>({
  position: { x, y },
  ...props
}: ShowContextMenuProps<T>) => {
  return new Promise<Nullable<T>>(resolve => {
    showModal<T>({
      children: ({ dismiss }) => (
        <ContextMenu onRequestClose={dismiss} {...props} />
      ),
      onClose: resolve,
      onRequestClose: ({ dismiss }) => dismiss(),
      transition: 'fadeInOutTop',
      style: {
        position: 'absolute',
        left: x,
        top: y,
      },
      barrierStyle: {
        backgroundColor: 'transparent',
      },
    })
  })
}

export default ContextMenu
