import {
  MFKey,
  MFMusicFile,
  MFSignature,
  MFTrack,
  MFTrackArray,
} from 'music-file'
import { DEFAULT_INSTRUMENT } from './instrument-collection'

export const UNTITLED_MUSIC_FILE = new MFMusicFile({
  name: 'Untitled',
  bpm: 90,
  signature: new MFSignature(4, 4),
  key: new MFKey('C'),
  numBars: 4,
  unitNoteType: 64,
  tracks: new MFTrackArray([
    new MFTrack({
      name: 'Track',
      instrument: DEFAULT_INSTRUMENT,
    }),
  ]),
})
