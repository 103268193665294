export const MUSIC_FILE_KEY_NAMES = [
    'C',
    'D',
    'E',
    'F',
    'G',
    'A',
    'B',
    'C#',
    'Cb',
    'Db',
    'Eb',
    'F#',
    'Gb',
    'Ab',
    'Bb',
];
export const MUSIC_FILE_ORDERED_KEY_NAMES = [
    'C',
    'C#',
    'D',
    'Eb',
    'E',
    'F',
    'F#',
    'G',
    'Ab',
    'A',
    'Bb',
    'B',
];
export const MUSIC_FILE_KEY_NAME_OFFSET_MAP = {
    C: 0,
    D: 2,
    E: 4,
    F: 5,
    G: 7,
    A: 9,
    B: 11,
    'C#': 1,
    Cb: 11,
    Db: 1,
    Eb: 3,
    'F#': 6,
    Gb: 6,
    Ab: 8,
    Bb: 10,
};
export class MFKey {
    constructor(name) {
        this.name = name;
    }
    static is(other) {
        return other instanceof MFKey;
    }
    static fromJSON(json) {
        if (json.__type !== 'key') {
            throw new Error('invalid key json type');
        }
        return new MFKey(ensureValidKeyName(json.name));
    }
    get offset() {
        return MUSIC_FILE_KEY_NAME_OFFSET_MAP[this.name];
    }
    getUpper(steps = 1) {
        const offset = this.offset + steps;
        if (offset < 0 || offset > MUSIC_FILE_ORDERED_KEY_NAMES.length - 1) {
            return this;
        }
        return new MFKey(MUSIC_FILE_ORDERED_KEY_NAMES[offset]);
    }
    getLower(steps = 1) {
        const offset = this.offset - steps;
        if (offset < 0 || offset > MUSIC_FILE_ORDERED_KEY_NAMES.length - 1) {
            return this;
        }
        return new MFKey(MUSIC_FILE_ORDERED_KEY_NAMES[offset]);
    }
    equals(other) {
        return other instanceof MFKey && other.name === this.name;
    }
    copy({ name } = {}) {
        return new MFKey(name !== null && name !== void 0 ? name : this.name);
    }
    toJSON() {
        return {
            __type: 'key',
            name: this.name,
        };
    }
}
export const isValidKeyName = (x) => {
    return MUSIC_FILE_KEY_NAMES.includes(x);
};
export const ensureValidKeyName = (x) => {
    if (!isValidKeyName(x)) {
        throw new Error(`${x} is not a valid key name`);
    }
    return x;
};
