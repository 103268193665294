import { MFKey } from './key.js';
import { MFSignature } from './signature.js';
import { MFTrack, MFTrackArray } from './track.js';
import { ensureValidUnitNoteType, } from './unit-note-type.js';
export const MUSIC_FILE_CURRENT_VERSION = '0.1-alpha';
export const MUSIC_FILE_SUPPORT_VERSIONS = ['0.1-alpha'];
export class MFMusicFile {
    constructor({ name, key, signature, unitNoteType, bpm, numBars, tracks, }) {
        var _a;
        this.name = name;
        this.key = key;
        this.signature = signature;
        this.unitNoteType = unitNoteType;
        this.bpm = bpm;
        this.numBars = numBars;
        this.tracks = tracks !== null && tracks !== void 0 ? tracks : new MFTrackArray();
        this.numTicksPerBeat = unitNoteType / signature.beatNoteType;
        this.numTicksPerBar = signature.numBeats * this.numTicksPerBeat;
        this.numTicks = numBars * this.numTicksPerBar;
        this.tickMs = (60 * 1000) / (this.numTicksPerBeat * bpm);
        this.milliseconds = this.numTicks * this.tickMs;
        const longestTrackTicks = (_a = this.tracks
            .toArray()
            .filter(track => track.items.length > 0)
            .map(track => track.items.last.end)
            .sort()
            .slice(-1)[0]) !== null && _a !== void 0 ? _a : 0;
        this.minValidNumTicks = longestTrackTicks;
        this.minValidNumBars = Math.ceil(longestTrackTicks / this.numTicksPerBar);
    }
    static is(other) {
        return other instanceof MFMusicFile;
    }
    static fromJSON(json) {
        if (json.__type !== 'musicFile') {
            throw new Error('invalid musicFile json type');
        }
        if (!MUSIC_FILE_SUPPORT_VERSIONS.includes(json.__version)) {
            throw new Error('unsupported musicFile version');
        }
        return new MFMusicFile({
            name: json.name,
            key: MFKey.fromJSON(json.key),
            signature: MFSignature.fromJSON(json.signature),
            unitNoteType: ensureValidUnitNoteType(json.unitNoteType),
            bpm: json.bpm,
            numBars: json.numBars,
            tracks: MFTrackArray.fromJSON(json.tracks),
        });
    }
    locate(target) {
        if (MFTrack.is(target)) {
            const index = this.tracks.indexOf(target);
            if (index >= 0) {
                return [index];
            }
            throw new Error('track does not exist');
        }
        for (let i = 0; i < this.tracks.length; i++) {
            const j = this.tracks.at(i).items.indexOf(target);
            if (j >= 0) {
                return [i, j];
            }
        }
        throw new Error('trackItem does not exist');
    }
    equals(other) {
        return (other instanceof MFMusicFile &&
            other.name === this.name &&
            other.key === this.key &&
            other.signature.equals(this.signature) &&
            other.unitNoteType === this.unitNoteType &&
            other.bpm === this.bpm &&
            other.numBars === this.numBars &&
            other.tracks.equals(this.tracks));
    }
    copy({ name, key, signature, unitNoteType, bpm, numBars, tracks, } = {}) {
        return new MFMusicFile({
            name: name !== null && name !== void 0 ? name : this.name,
            key: key !== null && key !== void 0 ? key : this.key,
            signature: signature !== null && signature !== void 0 ? signature : this.signature,
            unitNoteType: unitNoteType !== null && unitNoteType !== void 0 ? unitNoteType : this.unitNoteType,
            bpm: bpm !== null && bpm !== void 0 ? bpm : this.bpm,
            numBars: numBars !== null && numBars !== void 0 ? numBars : this.numBars,
            tracks: tracks !== null && tracks !== void 0 ? tracks : this.tracks,
        });
    }
    toJSON() {
        return {
            __type: 'musicFile',
            __version: MUSIC_FILE_CURRENT_VERSION,
            name: this.name,
            key: this.key.toJSON(),
            signature: this.signature.toJSON(),
            unitNoteType: this.unitNoteType,
            bpm: this.bpm,
            numBars: this.numBars,
            tracks: this.tracks.toJSON(),
        };
    }
}
