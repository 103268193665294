export const MUSIC_FILE_UNIT_NOTE_TYPES = [128, 64, 32, 16];
export const isValidUnitNoteType = (x) => {
    return MUSIC_FILE_UNIT_NOTE_TYPES.includes(x);
};
export const ensureValidUnitNoteType = (x) => {
    if (!isValidUnitNoteType(x)) {
        throw new Error(`${x} is not a valid unitNoteType`);
    }
    return x;
};
